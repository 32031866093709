import { ReactNode } from 'react';
import classNames from 'classnames';

export interface CellProps {
  className?: string;
  title?: string;
  value?: ReactNode;
}

export default function Cell({ className, title, value }: CellProps) {
  return (
    <div className={classNames('cell', className)} title={title}>
      {value}
    </div>
  );
}
