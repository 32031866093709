import { createStrictContext } from 'envoc-strict-context';

export interface ModalContext {
  openInstanceName: string;
  addVisible: (name: string) => void;
  removeVisible: (name: string) => void;
}

export const [ModalContextProvider, useModalContext] =
  createStrictContext<ModalContext>();
