/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static getLoggedInUserDetails(options: IRequestOptions = {}): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCookieExpiration(options: IRequestOptions = {}): Promise<DoubleApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/cookie-expiration';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLogin(
    params: {
      /**  */
      provider?: string;
      /**  */
      returnUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/ExternalLogin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provider: params['provider'], returnUrl: params['returnUrl'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLoginCallback(
    params: {
      /**  */
      returnUrl?: string;
      /**  */
      remoteError?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/ExternalLoginCallback';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'], remoteError: params['remoteError'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /**  */
      returnUrl?: string;
      /** requestBody */
      body?: RegisterUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: PasswordLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: ForgotPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/forgotpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotUsernameNonViewer(
    params: {
      /** requestBody */
      body?: ForgotUsernameNonViewerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/forgot-username-non-viewer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResetPasswordResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/resetpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/changepassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeEmail(
    params: {
      /** requestBody */
      body?: ChangeEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/changeemail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccountRedirect(
    params: {
      /**  */
      userName?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/confirm-account-redirect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userName: params['userName'], token: params['token'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccount(
    params: {
      /**  */
      userName?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountConfirmationResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/confirm-account';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { userName: params['userName'], token: params['token'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verifySms(
    params: {
      /** requestBody */
      body?: VerifySmsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/account/verify-sms';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSignedInViewerDetails(options: IRequestOptions = {}): Promise<ViewerDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/account/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static signOut(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/account/sign-out';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exchange(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/account/token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inviteViewersToFamily(
    params: {
      /** requestBody */
      body?: InviteViewersToFamilyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/account/invite';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static manageAccount(
    params: {
      /** requestBody */
      body?: ManageViewerAccountCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/account/manage';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AdminCamerasService {
  /**
   *
   */
  static getCameraDataTablePage(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/admin/locations/{locationId}/cameras/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createCamera(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: CreateCameraCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/admin/locations/{locationId}/cameras';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateCamera(
    params: {
      /**  */
      locationId: number;
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateCameraCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/admin/locations/{locationId}/cameras/{id}';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteCamera(
    params: {
      /**  */
      locationId: number;
      /**  */
      cameraId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/admin/locations/{locationId}/cameras/{cameraId}';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{cameraId}', params['cameraId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AdminFeatureFlagsService {
  /**
   *
   */
  static getFeatureFlags(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeatureFlagDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/admin/feature-flags/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static toggleFeatureFlag(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ToggleFeatureFlagDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/admin/feature-flags/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AdminOAuthClientsService {
  /**
   *
   */
  static getOAuthClientDataTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OAuthClientDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/admin/oauth-clients/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetSecret(
    params: {
      /** requestBody */
      body?: ResetOAuthSecretCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OAuthClientSecretDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/admin/oauth-clients/reset-secret';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AiPartnersService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AiPartnerConfigurationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/ai-partners/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationAiPartnerConfigurations(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationAiPartnerConfigurationsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/ai-partners/location';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLocationAiPartnerConfigurations(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: UpdateLocationAiPartnerConfigurationsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/ai-partners/location';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AuthenticationService {
  /**
   *
   */
  static exchange(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authorize(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/connect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static approve(
    params: {
      /** requestBody */
      body?: OAuthApprovalDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/approve';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserInfo(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/userinfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static signOut(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/sign-out';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class AutomationsService {
  /**
   *
   */
  static scheduleApproval(
    params: {
      /**  */
      viewerId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: ScheduleAutomatedApprovalCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/automations/approval/{viewerId}/schedule';
      url = url.replace('{viewerId}', params['viewerId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static scheduleViewerDeletion(
    params: {
      /**  */
      viewerId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: ScheduleViewerDeleteCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/automations/delete/{viewerId}/schedule';
      url = url.replace('{viewerId}', params['viewerId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateScheduleViewerDeletion(
    params: {
      /**  */
      scheduledViewerDeletionId?: number;
      /**  */
      viewerId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: UpdateScheduleViewerDeleteCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/automations/delete/{viewerId}/schedule-update';
      url = url.replace('{viewerId}', params['viewerId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { scheduledViewerDeletionId: params['scheduledViewerDeletionId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unscheduleViewerDeletion(
    params: {
      /**  */
      scheduledAutomationId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: UnscheduleViewerDeleteCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/automations/delete/{scheduledAutomationId}/unschedule';
      url = url.replace('{scheduledAutomationId}', params['scheduledAutomationId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bulkScheduleApproval(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: BulkScheduleAutomatedApprovalCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/automations/approval/schedule';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unscheduleApproval(
    params: {
      /**  */
      viewerId: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/automations/approval/{viewerId}/unschedule';
      url = url.replace('{viewerId}', params['viewerId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static scheduleAdditionOrDeletionClassTransfer(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: ScheduleClassesCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/automations/action-class-transfer';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateClassTransferScheduleDate(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: UpdateScheduleAutomationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/automations/class-transfer';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unscheduleClassAdditionOrRemovalTransfer(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: UnscheduleClassAdditionOrRemovalCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/automations/unshedule-class-transfer';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CameraService {
  /**
   *
   */
  static getCameras(options: IRequestOptions = {}): Promise<CameraListDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/cameras';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class CameraPoliciesService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: CameraPoliciesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraPolicyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSummaryById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraPolicySummaryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/summary';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailsById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraPolicyDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/details';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateDetails(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateCameraPolicyDetailsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/details';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getConfigById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraPolicyConfigurationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/config';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: CreateCameraPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateRestrictions(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateCameraPolicyConfigurationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/restrictions';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activateOrDeactivate(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ActivateOrDeactivateCameraPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/active';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: CameraPolicyCameraPolicyScheduleCameraPolicyScheduleEventEnableOrDisablePolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/enabled';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCameraOptions(
    params: {
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/table/cameras/options';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPolicyCamerasTable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: CamerasByPolicyDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyCameraListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/cameras';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CameraPolicyAssignmentsService {
  /**
   *
   */
  static assignAndActivatePolicyForCamera(
    params: {
      /**  */
      policyId?: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: AssignAndActivateCameraPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/cameras/assignments/assign-and-activate';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { policyId: params['policyId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CameraPolicySchedulesService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: CameraPolicyCameraPolicyScheduleCameraPolicyScheduleEventEnableOrDisablePolicyScheduleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEvents(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleEventDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEvent(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: CameraPolicyCameraPolicyScheduleCameraPolicyScheduleEventCreatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
      /** requestBody */
      body?: CameraPolicyCameraPolicyScheduleCameraPolicyScheduleEventUpdatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/cameras/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CamerasService {
  /**
   *
   */
  static getCameraById(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraSensitiveDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCameraOptions(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/options';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllCameraOptions(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/options/all';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCameraOptionsByLocation(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/options-by-location';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNvrOptions(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/cameras/nvrOptions';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ChildrenService {
  /**
   *
   */
  static getChild(
    params: {
      /**  */
      childId: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChildDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/children/{childId}';
      url = url.replace('{childId}', params['childId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateChild(
    params: {
      /**  */
      childId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: UpdateChildCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/children/{childId}';
      url = url.replace('{childId}', params['childId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteChild(
    params: {
      /**  */
      childId: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/children/{childId}';
      url = url.replace('{childId}', params['childId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getChildrenTable(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetApprovedChildrenByLocationIdTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChildListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/children/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getChildDeletionDetails(
    params: {
      /**  */
      childId: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChildDeletionDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/children/delete/{childId}';
      url = url.replace('{childId}', params['childId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ClassesService {
  /**
   *
   */
  static getClassDataTablePage(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getScheduledClassTransferTable(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetScheduledClassesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScheduledClassListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/table/transfers';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createClass(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: CreateClassCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassById(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateClass(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateClassCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteClass(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static assignCamerasToClass(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: AssignCamerasToClassCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/assign-camera-to-class/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassOptions(
    params: {
      /**  */
      filter?: string;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/options';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filter: params['filter'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static classTransferNow(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: TransferClassCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/transfer';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassOptions1(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/classes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static requestClassesChange(
    params: {
      /** requestBody */
      body?: RequestViewerClassChangeCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/classes/request-change';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ClassPoliciesService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: ClassPoliciesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassPolicyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSummaryById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassPolicySummaryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/summary';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailsById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassPolicyDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/details';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateDetails(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateClassPolicyDetailsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/details';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getConfigById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassPolicyConfigurationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/config';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: CreateClassPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateRestrictions(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateClassPolicyConfigurationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/restrictions';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activateOrDeactivate(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ActivateOrDeactivateClassPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/active';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ClassPolicyClassPolicyScheduleClassPolicyScheduleEventEnableOrDisablePolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/enabled';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassOptions(
    params: {
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/table/classes/options';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPolicyClassesTable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ClassesByPolicyDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyClassListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/classes';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ClassPolicyAssignmentsService {
  /**
   *
   */
  static assignAndActivatePolicyForClass(
    params: {
      /**  */
      policyId?: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: AssignAndActivateClassPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/classes/assignments/assign-and-activate';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { policyId: params['policyId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ClassPolicySchedulesService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ClassPolicyClassPolicyScheduleClassPolicyScheduleEventEnableOrDisablePolicyScheduleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEvents(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleEventDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEvent(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ClassPolicyClassPolicyScheduleClassPolicyScheduleEventCreatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
      /** requestBody */
      body?: ClassPolicyClassPolicyScheduleClassPolicyScheduleEventUpdatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/classes/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DashboardService {
  /**
   *
   */
  static getUserSummary(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardUserSummaryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/user-summary';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getActiveUsers(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardActiveUsersDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/active-users';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFamilyEngagement(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardFamilyEngagementDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/family-engagement';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTopUsers(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardTopUsersDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/top-users';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getResources(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardResourcesDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/resources';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getImages(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardImagesDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/images';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAutomations(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: GetScheduledAnimationsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScheduledAutomationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/automations';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventsAndAutomations(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: GetEventsAndAutomationsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventOrAutomationListItemDtoIEnumerableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/events-and-automations';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNotifications(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardNotificationsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/notifications';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassDetectionCountAvailability(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/available/class-detection-count';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassDetectionCount(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassDetectionCountDtoArrayApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/dashboard/class-detection-count';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DebugToolsService {
  /**
   *
   */
  static createApprovedFamily(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: DebugCreateApprovedFamilyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DebugApprovedFamilyResultDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/approved-family';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetIntegrationForLocation(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/reset-integration';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeLocationClaimsFromCookie(
    params: {
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/remove-location-claims';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static scoutXUnaccompaniedChild(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DebugSendScoutXWebhookCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/scoutx-unaccompanied-child';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static blueIrisLicensePlate(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DebugSendBlueIrisWebhookCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/blueiris-license-plate';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static scoutXAddCamera(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DebugScoutxAddCameraCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/scoutx-add-camera';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static scoutXRemoveCamera(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DebugScoutxRemoveCameraCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/scoutx-remove-camera';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendNotifications(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DebugPushNotificationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/send-notification';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cacheCameraStream(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DebugCacheCameraStreamMessageCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/cache-camera-stream';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static debugNvrUser(
    params: {
      /**  */
      userId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DebugNvrUserDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/nvr-users/{userId}';
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeIntegrationEnrollmentLocationExternalId(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DebugChangeIntegrationLocationExternalIdCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/location-external-id';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createIntegrationEnrollmentImportLog(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DebugCreateIntegrationEnrollmentImportLogCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/debug/create-import-log';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EventsService {
  /**
   *
   */
  static getFutureEvents(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetFutureEventsByLocationIdDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events/future/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPastEvents(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetPastEventsByLocationIdDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PastEventListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events/past/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventById(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEvent(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: CreateEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEvent(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventDeletionDetails(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDeleteDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events/delete/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteEventThumbnailImage(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events/{eventId}/delete-thumbnail';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEventEmailNotificationDetails(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventSendEmailNotificationsDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events/notify/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendEventEmails(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events/notify/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getChildOptions(
    params: {
      /**  */
      query?: string;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/events/child-options';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { query: params['query'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FamiliesService {
  /**
   *
   */
  static getApprovedFamiliesDataTablePage(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetApprovedFamiliesByLocationIdRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FamilyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFamily(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FamilyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteFamily(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeFamilyName(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: ChangeFamilyNameCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}/name';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePrimaryUser(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: ChangeFamilyPrimaryUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}/primary-user';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTransferFamilyMemberDetails(
    params: {
      /**  */
      viewerId: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FamilyMemberTransferDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/transfer-family-member/{viewerId}';
      url = url.replace('{viewerId}', params['viewerId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static transferFamilyMember(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: TransferFamilyMemberCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}/transfer-family-member';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFamilyChildren(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FamilyChildListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}/children/table';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createChild(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: CreateChildCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}/children';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFamilyViewers(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: GetViewersByFamilyDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}/viewers/table';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getApprovedViewerOptions(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}/users/options';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getApprovedGuardianViewerOptions(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}/guardians/options';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFamilyDetailsForMerge(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FamilyDetailsForMergeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}/details-for-merge';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static viewerCameraSessionsInvalidateSessionsFromTodayByFamily(
    params: {
      /**  */
      familyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: InvalidateViewerCameraSessionsFromTodayByFamilyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/{familyId}/camera-sessions/invalidate-today';
      url = url.replace('{familyId}', params['familyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFamiliesForMergeOptions(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetFamilyOptionsForMergeQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetFamilyOptionsQueryDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/options';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mergeApprovedFamilies(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: MergeApprovedFamiliesCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32NullableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/merge-approved-families';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mergeUnapprovedFamilies(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: MergeUnapprovedFamiliesCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32NullableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/merge-unapproved-families';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mergeApprovedIntoUnapprovedFamilies(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: MergeApprovedIntoUnapprovedFamilyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32NullableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/merge-unapproved-into-approved-families';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FamilyPoliciesService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: FamilyPoliciesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FamilyPolicyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSummaryById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FamilyPolicySummaryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/summary';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailsById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FamilyPolicyDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/details';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateDetails(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateFamilyPolicyDetailsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/details';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getConfigById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FamilyPolicyConfigurationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/config';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: CreateFamilyPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateRestrictions(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateFamilyPolicyConfigurationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/restrictions';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activateOrDeactivate(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ActivateOrDeactivateFamilyPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/active';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: FamilyPolicyFamilyPolicyScheduleFamilyPolicyScheduleEventEnableOrDisablePolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/enabled';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFamilyOptions(
    params: {
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/table/families/options';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPolicyFamiliesTable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: FamiliesByPolicyDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyFamilyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/families';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FamilyPolicyAssignmentsService {
  /**
   *
   */
  static assignAndActivatePolicyForFamily(
    params: {
      /**  */
      policyId?: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: AssignAndActivateFamilyPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/families/assignments/assign-and-activate';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { policyId: params['policyId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FamilyPolicySchedulesService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: FamilyPolicyFamilyPolicyScheduleFamilyPolicyScheduleEventEnableOrDisablePolicyScheduleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEvents(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleEventDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEvent(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: FamilyPolicyFamilyPolicyScheduleFamilyPolicyScheduleEventCreatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
      /** requestBody */
      body?: FamilyPolicyFamilyPolicyScheduleFamilyPolicyScheduleEventUpdatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/families/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FeaturesService {
  /**
   *
   */
  static getFeatures(options: IRequestOptions = {}): Promise<FeatureFlagTypeListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/features';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFeaturesAsViewer(options: IRequestOptions = {}): Promise<FeatureFlagTypeListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/features/viewer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIsLprEnabledAtLocation(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/features/lpr/{locationId}';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIsLprEnabledAtInviteLocation(
    params: {
      /**  */
      inviteId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/features/lpr/invite/{inviteId}';
      url = url.replace('{inviteId}', params['inviteId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationFeatureEnabled(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeatureFlagTypeListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/features/location/{locationId}';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FileResourcesService {
  /**
   *
   */
  static getBySasToken(
    params: {
      /**  */
      fileResourceGuid: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/file-resources/{fileResourceGuid}';
      url = url.replace('{fileResourceGuid}', params['fileResourceGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class IntegrationNvrService {
  /**
   *
   */
  static getCameraStreamUrl(
    params: {
      /**  */
      cameraId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraStreamDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/nvr/camera/{cameraId}';
      url = url.replace('{cameraId}', params['cameraId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNvrSeverSession(
    params: {
      /**  */
      serverId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IntegrationNvrSessionDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/nvr/server/{serverId}/session';
      url = url.replace('{serverId}', params['serverId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCamerasByLocation(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IntegratedLocationCameraICollectionApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/nvr/location/{locationId}/cameras';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class IntegrationPolicyService {
  /**
   *
   */
  static updateConfiguration(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateIntegrationPolicyConfigurationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/integrations/policies/{policyId}/configuration';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getConfigurationById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IntegrationPolicyConfigurationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/integrations/policies/{policyId}/configuration';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class IntegrationPolicySchedulesService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/integrations/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: IntegrationPolicyIntegrationPolicyScheduleIntegrationPolicyScheduleEventEnableOrDisablePolicyScheduleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/integrations/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEvents(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleEventDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/integrations/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEvent(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: IntegrationPolicyIntegrationPolicyScheduleIntegrationPolicyScheduleEventCreatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/integrations/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
      /** requestBody */
      body?: IntegrationPolicyIntegrationPolicyScheduleIntegrationPolicyScheduleEventUpdatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/integrations/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/integrations/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class IntegrationsService {
  /**
   *
   */
  static getIntegrations(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IntegrationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/integrations/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIntegration(
    params: {
      /**  */
      integrationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IntegrationListItemDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/integrations/{integrationId}';
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIntegrationApiKeys(
    params: {
      /**  */
      integrationId: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApiKeyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/integrations/{integrationId}/api-keys';
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateApiKey(
    params: {
      /**  */
      integrationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApiKeyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/integrations/{integrationId}/api-key/generate';
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateApiKey(
    params: {
      /** requestBody */
      body?: UpdateApiKeyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/integrations/api-key/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LicensePlatesService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LicensePlateListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/license-plates/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateLicensePlateCommandDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/license-plates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LicensePlateDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/license-plates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateLicensePlateCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/license-plates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/license-plates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getViewerOptions(
    params: {
      /**  */
      query?: string;
      /**  */
      licensePlateId?: number;
      /**  */
      locationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/license-plates/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        query: params['query'],
        licensePlateId: params['licensePlateId'],
        locationId: params['locationId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadCsv(
    params: {
      /**  */
      file: any;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/license-plates/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('File', item as any);
          }
        } else {
          data.append('File', params['file'] as any);
        }
      }

      if (params['locationId']) {
        if (Object.prototype.toString.call(params['locationId']) === '[object Array]') {
          for (const item of params['locationId']) {
            data.append('LocationId', item as any);
          }
        } else {
          data.append('LocationId', params['locationId'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LocationPoliciesService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationPolicyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSummaryById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationPolicySummaryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/summary';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailsById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationPolicyDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/details';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateDetails(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateLocationPolicyDetailsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/details';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getConfigurationById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationPolicyConfigurationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/configuration';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateConfiguration(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateLocationPolicyConfigurationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/configuration';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: CreateLocationPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activateOrDeactivate(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ActivateOrDeactivateLocationPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/active';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: EnableOrDisableLocationPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/enabled';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LocationPolicySchedulesService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: LocationPolicyLocationPolicyScheduleLocationPolicyScheduleEventEnableOrDisablePolicyScheduleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEvents(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleEventDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEvent(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: LocationPolicyLocationPolicyScheduleLocationPolicyScheduleEventCreatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
      /** requestBody */
      body?: LocationPolicyLocationPolicyScheduleLocationPolicyScheduleEventUpdatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class LocationsService {
  /**
   *
   */
  static getLocationDataTablePage(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationUsersDataTablePage(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationUserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/users/table';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createLocation(
    params: {
      /** requestBody */
      body?: CreateLocationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLocation(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateLocationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteLocation(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationNotificationSettings(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationNotificationSettingsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/notifications';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLocationNotificationSettings(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateLocationNotificationSettingsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/notifications';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDeleteDetails(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeleteEntityDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/delete';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addUsersToLocation(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: AddUsersToLocationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/users';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeUserFromLocation(
    params: {
      /**  */
      id: number;
      /**  */
      userId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/users/{userId}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNvrDeleteDetails(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeleteEntityDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/nvr/delete-details';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteNvrServer(
    params: {
      /**  */
      id: number;
      /**  */
      nvrServerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/nvr/{nvrServerId}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{nvrServerId}', params['nvrServerId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNvrCameraDataTablePage(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: GetNvrServerCameraDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NvrServerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/nvr/cameras/table';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNvrDataTablePage(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: GetNvrServerDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NvrServerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/nvr/table';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNvrServer(
    params: {
      /**  */
      id: number;
      /**  */
      nvrServerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NvrServerSensitiveDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/nvr';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { nvrServerId: params['nvrServerId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createNvrServer(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateNvrServerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/nvr';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateNvrServer(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateNvrServerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/nvr';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateBlueIrisJwtForNvrServer(
    params: {
      /**  */
      id: number;
      /**  */
      nvrServerId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/nvr/generate-blueiris-jwt';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { nvrServerId: params['nvrServerId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationNvrServerCount(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationNvrServerCountDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/nvr/count';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationUserInviteOptions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/users/invite-options';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationOrganizationId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32NullableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/organization';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getExternalNvrLink(
    params: {
      /**  */
      id: number;
      /**  */
      nvrServerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalNvrLinksDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/external-nvr/{nvrServerId}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{nvrServerId}', params['nvrServerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationOptionItems(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCurrentLocation(options: IRequestOptions = {}): Promise<CurrentLocationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/get-current-location';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setCurrentLocation(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/set-current-location-cookie/{locationId}';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAdminPolicy(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdminLocationPolicyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/admin-policy';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setAdminPolicy(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SetAdminLocationPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/admin-policy';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static integrationForceSync(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{id}/force-integration-sync';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static viewerCameraSessionsInvalidateSessionsFromTodayByLocation(
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/camera-sessions/invalidate-today';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRequestCount(options: IRequestOptions = {}): Promise<LocationRequestCountDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/request-count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationOptions(options: IRequestOptions = {}): Promise<LocationOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/locations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MarketplaceService {
  /**
   *
   */
  static getIntegrations(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MarketplaceIntegrationDtoIEnumerableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/marketplace/integrations';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enroll(
    params: {
      /**  */
      locationId: number;
      /**  */
      integrationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/marketplace/integration/{integrationId}/connect';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationEnrollmentStatus(
    params: {
      /**  */
      locationId: number;
      /**  */
      integrationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationIntegrationEnrollmentDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/marketplace/integration/{integrationId}/connect/status';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setLocationKey(
    params: {
      /**  */
      locationId: number;
      /**  */
      integrationId: number;
      /** requestBody */
      body?: SetLocationKeyForIntegrationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/marketplace/integration/{integrationId}/connect/set-key';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static importClasses(
    params: {
      /**  */
      locationId: number;
      /**  */
      integrationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/marketplace/integration/{integrationId}/connect/classes';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static importFamilies(
    params: {
      /**  */
      locationId: number;
      /**  */
      integrationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/marketplace/integration/{integrationId}/connect/families';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unenroll(
    params: {
      /**  */
      locationId: number;
      /**  */
      integrationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/marketplace/integration/{integrationId}/connect/unenroll';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class MobileConfigurationService {
  /**
   *
   */
  static checkForForcedUpdate(
    params: {
      /**  */
      version: string;
      /**  */
      platform: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/mobile-configuration/{version}/{platform}';
      url = url.replace('{version}', params['version'] + '');
      url = url.replace('{platform}', params['platform'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MobileRoomRequestService {
  /**
   *
   */
  static getChildOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/room-request/child/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/room-request/class/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submit(
    params: {
      /** requestBody */
      body?: SubmitRoomRequestCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/room-request/submit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class NotificationsService {
  /**
   *
   */
  static registerToken(
    params: {
      /** requestBody */
      body?: RegisterTokenCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/notifications/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class OptionsService {
  /**
   *
   */
  static getLocationOptions(
    params: {
      /**  */
      filter?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/options/locations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filter: params['filter'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationRegistrationOptions(
    params: {
      /**  */
      filter?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/options/registration-locations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filter: params['filter'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTimeZoneOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/options/time-zones';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassOrFamilyChildrenOptions(
    params: {
      /** requestBody */
      body?: GetClassOrFamilyChildrenQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/options/class-or-family-children';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getViewerTypeOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/options/viewer-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassTransferTypeOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/options/class-schedule-transfer-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCountryAndTerritoryOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/options/countries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassOptions(
    params: {
      /**  */
      locationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/options/classes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { locationId: params['locationId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFamilyOptions(
    params: {
      /**  */
      locationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/options/families';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { locationId: params['locationId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getChildrenOptions(
    params: {
      /**  */
      locationId?: number;
      /**  */
      familyId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/options/children';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { locationId: params['locationId'], familyId: params['familyId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class OrganizationsService {
  /**
   *
   */
  static getOrganizations(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocations(
    params: {
      /** requestBody */
      body?: GetOrganizationLocationsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationLocationsListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/locations/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOrganizationUsers(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: GetOrganizationUsersDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationUserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/{id}/users/table';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createOrganization(
    params: {
      /** requestBody */
      body?: CreateOrganizationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOrganizationById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateOrganization(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateOrganizationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteOrganization(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDeleteDetails(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeleteEntityDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/{id}/delete';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOrganizationOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOrganizationLocationUsers(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/{id}/location-users';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOrganizationLocationOptions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/{id}/location-options';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProgramTypeOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/program-type-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOrganizationRegionOptions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/{id}/region-options';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lockUsersAccounts(
    params: {
      /**  */
      id?: number;
      /**  */
      lockoutEndDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/organizations/lock';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'], lockoutEndDate: params['lockoutEndDate'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PreferencesService {
  /**
   *
   */
  static getNotificationsPreferences(options: IRequestOptions = {}): Promise<NotificationPreferencesDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/preferences/notifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editNotificationPreferences(
    params: {
      /** requestBody */
      body?: UpdateUserNotificationPreferencesCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/preferences/notifications';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMobileNvrUser(
    params: {
      /**  */
      serverId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32NullableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/preferences/mobile-nvr/{serverId}/nvr-user';
      url = url.replace('{serverId}', params['serverId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static regenerateMobileNvrUser(
    params: {
      /**  */
      serverId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/preferences/mobile-nvr/{serverId}/nvr-user/generate';
      url = url.replace('{serverId}', params['serverId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMobileQrCode(
    params: {
      /**  */
      serverId: number;
      /**  */
      nvrUserId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/preferences/mobile-nvr/{serverId}/nvr-user/{nvrUserId}/qr-code';
      url = url.replace('{serverId}', params['serverId'] + '');
      url = url.replace('{nvrUserId}', params['nvrUserId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PublicSettingsService {
  /**
   *
   */
  static getGeneralSettings(options: IRequestOptions = {}): Promise<GeneralSettingsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/public/settings/general-settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMobileLinks(options: IRequestOptions = {}): Promise<MobileLinksDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/public/settings/mobile-links';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLiveSitterSettings(options: IRequestOptions = {}): Promise<LiveSitterSettingsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/public/settings/livesitter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAiDashboardSettings(options: IRequestOptions = {}): Promise<AiDashboardSettingsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/public/settings/ai-dashboard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class RegionsService {
  /**
   *
   */
  static getRegionDataTablePage(
    params: {
      /** requestBody */
      body?: GetRegionDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RegionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/regions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createRegion(
    params: {
      /** requestBody */
      body?: CreateRegionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/regions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateRegion(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateRegionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/regions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteRegion(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/regions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRegionById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RegionDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/regions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationOptions(
    params: {
      /**  */
      organizationId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/regions/location-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { organizationId: params['organizationId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRegionOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/regions/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class RegistrationsService {
  /**
   *
   */
  static registerNewFamily(
    params: {
      /** requestBody */
      body?: CreateFamilyRegistrationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/registrations/family';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static registerNewViewer(
    params: {
      /** requestBody */
      body?: CreateViewerRegistrationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/registrations/viewer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inviteViewersToFamily(
    params: {
      /** requestBody */
      body?: InviteViewersToFamilyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/registrations/invite';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInvitationStatus(
    params: {
      /**  */
      inviteId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InvitationStatusApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/registrations/invite';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { inviteId: params['inviteId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getIntegratedViewerInvitation(
    params: {
      /**  */
      inviteId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IntegratedViewerInvitationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/registrations/integration/invite';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { inviteId: params['inviteId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static registerIntegratedViewer(
    params: {
      /** requestBody */
      body?: RegisterIntegratedViewerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/registrations/integration/viewer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static validateMyInfoStep(
    params: {
      /** requestBody */
      body?: ValidateMyInfoStepQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/registrations/validate/info';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static validateChildrenStep(
    params: {
      /** requestBody */
      body?: ValidateChildrenStepQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/registrations/validate/children';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static validateAccountStep(
    params: {
      /** requestBody */
      body?: ValidateAccountStepQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/registrations/validate/account';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resendLocationInvitations(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/registrations/integration/resend-invitations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPendingRegistrations(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetPendingRegistrationsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PendingRegistrationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/registrations/table/pending';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getScheduledRegistrations(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetScheduledRegistrationsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScheduledRegistrationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/registrations/table/scheduled';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDeniedRegistrations(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetDeniedRegistrationsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeniedRegistrationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/registrations/table/denied';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static evaluate(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: EvaluateViewerRegistrationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/registrations/{id}/evaluate';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bulkEvaluate(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: BulkEvaluateViewerRegistrationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/registrations/evaluate';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: DeleteViewerRegistrationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/registrations/delete';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bulkDelete(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: BulkDeleteViewerRegistrationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/registrations/bulk-delete';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class RoomRequestsService {
  /**
   *
   */
  static getDataTable(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetRoomRequestsDataTableQueryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoomRequestListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/room-requests/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static evaluate(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: EvaluateRoomRequestCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/room-requests/{id}/evaluate';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getSettingByKey(
    params: {
      /**  */
      key: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/settings/{key}';
      url = url.replace('{key}', params['key'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/settings/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/settings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static getStatusAsync(options: IRequestOptions = {}): Promise<StatusResultListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class StreamService {
  /**
   *
   */
  static getManifest(
    params: {
      /**  */
      id: number;
      /**  */
      sas?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/viewing/stream/camera/{id}.m3u8';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { sas: params['sas'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getChunk(
    params: {
      /**  */
      guid: string;
      /**  */
      sas?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/viewing/stream/chunk/{guid}.ts';
      url = url.replace('{guid}', params['guid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { sas: params['sas'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLoadingChunk(
    params: {
      /**  */
      sequence: number;
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/viewing/stream/chunk/loading{sequence}_{id}.ts';
      url = url.replace('{sequence}', params['sequence'] + '');
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBlueIrisConfig(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraBlueIrisConfigDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/viewing/stream/camera/{id}/blue-iris';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateCache(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CameraMediaPlaylistDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/viewing/stream/camera/{id}/cache';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class StreamShieldService {
  /**
   *
   */
  static unlock(
    params: {
      /** requestBody */
      body?: StreamShieldAccountReactivationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/stream-shield/unlock';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class UserNvrServersService {
  /**
   *
   */
  static getUserLocationsWithNvrServerCount(
    params: {
      /** requestBody */
      body?: UserLocationsWithNvrServerCountDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserLocationsWithNvrServerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/user-nvr-servers/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNvrServerNavigation(
    params: {
      /**  */
      serverId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NvrServerNavigationDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/user-nvr-servers/{serverId}/navigate';
      url = url.replace('{serverId}', params['serverId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationAvailableNvrServers(
    params: {
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32ListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/user-nvr-servers/{locationId}/servers';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationNvrServerLinksDataTablePage(
    params: {
      /**  */
      locationId: number;
      /** requestBody */
      body?: GetConfiguredNvrServersByLocationDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConfiguredNvrServerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/user-nvr-servers/{locationId}/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLocationNvrTerritoryOptions(options: IRequestOptions = {}): Promise<OptionItemDtoIEnumerableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/user-nvr-servers/territory-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNvrServerOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/user-nvr-servers/mobile-nvr-options';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static getProfilePhoto(
    params: {
      /**  */
      userId: number;
      /**  */
      profilePhotoGuid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/{userId}/profilePhoto/{profilePhotoGuid}';
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{profilePhotoGuid}', params['profilePhotoGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUsers(
    params: {
      /** requestBody */
      body?: GetUsersDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putUserNoPhoto(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUser(
    params: {
      /** requestBody */
      body?: CreateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lockUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/{id}/lock';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unlockUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/{id}/unlock';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleOptions(
    params: {
      /**  */
      roleContext?: RoleContextType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/roleOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { roleContext: params['roleContext'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/sendConfirmEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendVerifySms(
    params: {
      /** requestBody */
      body?: SendVerificationSmsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/sendVerifySms';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserLocationAccessCount(options: IRequestOptions = {}): Promise<Int32ApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/location-access-count';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllOptionalNotifications(options: IRequestOptions = {}): Promise<UserNotificationsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/users/notifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ViewerCameraSessionService {
  /**
   *
   */
  static create(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/camera-sessions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create1(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/camera-sessions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static processScreenShieldViolation(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/camera-sessions/stream-shield-violation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ViewerPoliciesService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: ViewerPoliciesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerPolicyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSummaryById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerPolicySummaryDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/summary';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailsById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerPolicyDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/details';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateDetails(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateViewerPolicyDetailsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/details';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getConfigById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerPolicyConfigurationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/config';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: CreateViewerPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateRestrictions(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateViewerPolicyConfigurationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/restrictions';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activateOrDeactivate(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ActivateOrDeactivateViewerPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/active';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ViewerPolicyViewerPolicyScheduleViewerPolicyScheduleEventEnableOrDisablePolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/enabled';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getViewerOptions(
    params: {
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/table/viewers/options';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPolicyViewersTable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ViewersByPolicyDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyViewerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/viewers';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ViewerPolicyAssignmentsService {
  /**
   *
   */
  static assignAndActivatePolicyForViewer(
    params: {
      /**  */
      policyId?: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: AssignAndActivateViewerPolicyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/policies/viewers/assignments/assign-and-activate';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { policyId: params['policyId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ViewerPolicySchedulesService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static enableOrDisable(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ViewerPolicyViewerPolicyScheduleViewerPolicyScheduleEventEnableOrDisablePolicyScheduleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/schedule';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEvents(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyScheduleEventDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEvent(
    params: {
      /**  */
      policyId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: ViewerPolicyViewerPolicyScheduleViewerPolicyScheduleEventCreatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/schedule/events';
      url = url.replace('{policyId}', params['policyId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
      /** requestBody */
      body?: ViewerPolicyViewerPolicyScheduleViewerPolicyScheduleEventUpdatePolicyScheduleEventCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteEvent(
    params: {
      /**  */
      eventId: number;
      /**  */
      locationId: string;
      /**  */
      policyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/viewers/policies/{policyId}/schedule/events/{eventId}';
      url = url.replace('{eventId}', params['eventId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{policyId}', params['policyId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ViewersService {
  /**
   *
   */
  static getActiveViewers(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetActiveViewersDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActiveViewerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/active/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInactiveViewers(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetInactiveViewersDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InactiveViewerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/inactive/table';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInactiveViewerCount(
    params: {
      /**  */
      locationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32ApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/inactive/count';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getViewerById(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateViewer(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateViewerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteViewer(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: number;
      /** requestBody */
      body?: DeleteViewerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateViewerLicensePlate(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: UpdateViewerLicensePlateCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/licensePlate/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getViewerDeletionDetails(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerDeletionDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/delete/{id}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lockViewerAccount(
    params: {
      /**  */
      id: number;
      /**  */
      lockoutEndDate?: string;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/{id}/lock';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { lockoutEndDate: params['lockoutEndDate'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unlockViewerAccount(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/{id}/unlock';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getScheduledViewerDeletionTable(
    params: {
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetScheduledViewerDeletionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScheduledViewerDeletionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/table/scheduled-deletions';
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getViewerCameraSessionsByViewer(
    params: {
      /**  */
      id: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: GetViewerCameraSessionsByViewerDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerCameraSessionByViewerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/{id}/table/camera-sessions';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static viewerCameraSessionsValidOrInvalid(
    params: {
      /**  */
      locationId: number;
      /**  */
      viewerId: number;
      /**  */
      viewerCameraSessionId: number;
      /** requestBody */
      body?: SessionValidOrInvalidCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url =
        basePath + '/api/WebPortal/locations/{locationId}/viewers/{viewerId}/camera-sessions/{viewerCameraSessionId}';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{viewerId}', params['viewerId'] + '');
      url = url.replace('{viewerCameraSessionId}', params['viewerCameraSessionId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static viewerCameraSessionsInvalidateSessionsFromTodayByViewer(
    params: {
      /**  */
      viewerId: number;
      /**  */
      locationId: string;
      /** requestBody */
      body?: InvalidateViewerCameraSessionsFromTodayByViewerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/{viewerId}/camera-sessions/invalidate-today';
      url = url.replace('{viewerId}', params['viewerId'] + '');
      url = url.replace('{locationId}', params['locationId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendResetViewerPassword(
    params: {
      /**  */
      locationId: number;
      /**  */
      viewerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/{viewerId}/reset-password';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{viewerId}', params['viewerId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getViewerCameraSessionTotalsByViewer(
    params: {
      /**  */
      locationId: number;
      /**  */
      viewerId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViewerCameraSessionsUsedDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebPortal/locations/{locationId}/viewers/{viewerId}/camera-session-count';
      url = url.replace('{locationId}', params['locationId'] + '');
      url = url.replace('{viewerId}', params['viewerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ViewerSignInSessionService {
  /**
   *
   */
  static createOrUpdate(
    params: {
      /** requestBody */
      body?: CreateOrUpdateViewerSignInSessionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidNullableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mobile/sign-in-sessions/notify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WebViewAccountService {
  /**
   *
   */
  static getSignedInViewerDetails(options: IRequestOptions = {}): Promise<ViewerDetailsDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/account/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static signOut(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/account/sign-out';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exchange(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/account/token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inviteViewersToFamily(
    params: {
      /** requestBody */
      body?: InviteViewersToFamilyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/account/invite';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static manageAccount(
    params: {
      /** requestBody */
      body?: ManageViewerAccountCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/account/manage';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WebViewCameraService {
  /**
   *
   */
  static getCameras(options: IRequestOptions = {}): Promise<CameraListDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/cameras';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPublicCameras(
    params: {
      /**  */
      publicId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PublicCameraDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/cameras/public/{publicId}';
      url = url.replace('{publicId}', params['publicId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPasswordProtectedPublicCameras(
    params: {
      /**  */
      publicId: string;
      /**  */
      password?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PublicCameraDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/cameras/public/{publicId}/unlock';
      url = url.replace('{publicId}', params['publicId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { password: params['password'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class WebViewerRoomRequestService {
  /**
   *
   */
  static getChildOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/room-request/child/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getClassOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/room-request/class/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submit(
    params: {
      /** requestBody */
      body?: SubmitRoomRequestCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/room-request/submit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class WebViewViewerSignInSessionService {
  /**
   *
   */
  static createOrUpdate(
    params: {
      /** requestBody */
      body?: CreateOrUpdateViewerSignInSessionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidNullableApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/WebView/sign-in-sessions/notify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export interface AccountConfirmationResult {
  /**  */
  isPasswordResetRequired?: boolean;

  /**  */
  code?: string;
}

export interface AccountConfirmationResultApiResult {
  /**  */
  result?: AccountConfirmationResult;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ActivateOrDeactivateCameraPolicyCommand {
  /**  */
  cameraId?: number;

  /**  */
  cameraPolicyId?: number;

  /**  */
  isActive?: boolean;
}

export interface ActivateOrDeactivateClassPolicyCommand {
  /**  */
  classId?: number;

  /**  */
  classPolicyId?: number;

  /**  */
  isActive?: boolean;
}

export interface ActivateOrDeactivateFamilyPolicyCommand {
  /**  */
  familyId?: number;

  /**  */
  familyPolicyId?: number;

  /**  */
  isActive?: boolean;
}

export interface ActivateOrDeactivateLocationPolicyCommand {
  /**  */
  id?: number;

  /**  */
  isActive?: boolean;
}

export interface ActivateOrDeactivateViewerPolicyCommand {
  /**  */
  viewerId?: number;

  /**  */
  viewerPolicyId?: number;

  /**  */
  isActive?: boolean;
}

export interface ActiveViewerListItemDto {
  /**  */
  fullName?: string;

  /**  */
  userName?: string;

  /**  */
  isLockedOut?: boolean;

  /**  */
  isScheduledForDeletion?: boolean;

  /**  */
  children?: string[];

  /**  */
  viewerType?: string;

  /**  */
  familyName?: string;

  /**  */
  familyId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  viewerPolicyName?: string;

  /**  */
  viewerPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;

  /**  */
  scheduledViewerDeletionId?: number;

  /**  */
  scheduledDate?: string;

  /**  */
  isPrimaryUser?: boolean;

  /**  */
  id?: number;
}

export interface ActiveViewerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ActiveViewerListItemDto[];
}

export interface ActiveViewerListItemDtoDataTablePageApiResult {
  /**  */
  result?: ActiveViewerListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AddUsersToLocationCommand {
  /**  */
  locationId?: number;

  /**  */
  users?: number[];
}

export interface AddressDto {
  /**  */
  line1?: string;

  /**  */
  line2?: string;

  /**  */
  city?: string;

  /**  */
  countryId?: number;

  /**  */
  territoryId?: number;

  /**  */
  postalCode?: string;
}

export interface AdminDesktopAccessConfigurationItemDto {
  /**  */
  value?: boolean;

  /**  */
  enforcementType?: AdminPolicyEnforcementType;
}

export interface AdminGuardianMaxCountConfigurationItemDto {
  /**  */
  value?: number;

  /**  */
  enforcementType?: AdminPolicyEnforcementType;
}

export interface AdminLocationPolicyDto {
  /**  */
  id?: number;

  /**  */
  locationId?: number;

  /**  */
  guardianMaxCountRestriction?: AdminGuardianMaxCountConfigurationItemDto;

  /**  */
  relativeMaxCountRestriction?: AdminRelativeMaxCountConfigurationItemDto;

  /**  */
  sessionCountRestriction?: AdminSessionCountConfigurationItemDto;

  /**  */
  sessionLengthRestriction?: AdminSessionLengthConfigurationItemDto;

  /**  */
  desktopAccessRestriction?: AdminDesktopAccessConfigurationItemDto;

  /**  */
  mobileAccessRestriction?: AdminMobileAccessConfigurationItemDto;

  /**  */
  streamShieldRestriction?: AdminStreamShieldConfigurationItemDto;
}

export interface AdminLocationPolicyDtoApiResult {
  /**  */
  result?: AdminLocationPolicyDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AdminMobileAccessConfigurationItemDto {
  /**  */
  value?: boolean;

  /**  */
  enforcementType?: AdminPolicyEnforcementType;
}

export interface AdminRelativeMaxCountConfigurationItemDto {
  /**  */
  value?: number;

  /**  */
  enforcementType?: AdminPolicyEnforcementType;
}

export interface AdminSessionCountConfigurationItemDto {
  /**  */
  value?: number;

  /**  */
  enforcementType?: AdminPolicyEnforcementType;
}

export interface AdminSessionLengthConfigurationItemDto {
  /**  */
  value?: number;

  /**  */
  enforcementType?: AdminPolicyEnforcementType;
}

export interface AdminStreamShieldConfigurationItemDto {
  /**  */
  value?: boolean;

  /**  */
  enforcementType?: AdminPolicyEnforcementType;
}

export interface AiDashboardSettingsDto {
  /**  */
  videoLink?: string;

  /**  */
  discoveryCallLink?: string;

  /**  */
  additionalResourcesLink?: string;
}

export interface AiDashboardSettingsDtoApiResult {
  /**  */
  result?: AiDashboardSettingsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AiPartnerConfigurationDto {
  /**  */
  id?: number;

  /**  */
  locationId?: number;

  /**  */
  partnerName?: string;
}

export interface AiPartnerConfigurationDtoApiResult {
  /**  */
  result?: AiPartnerConfigurationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ApiKeyDto {
  /**  */
  value?: string;
}

export interface ApiKeyDtoApiResult {
  /**  */
  result?: ApiKeyDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ApiKeyListItemDto {
  /**  */
  id?: number;

  /**  */
  value?: string;

  /**  */
  generatedByUserUserName?: string;

  /**  */
  createdDate?: string;

  /**  */
  isValid?: boolean;
}

export interface ApiKeyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ApiKeyListItemDto[];
}

export interface ApiKeyListItemDtoDataTablePageApiResult {
  /**  */
  result?: ApiKeyListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AssignAndActivateCameraPolicyCommand {
  /**  */
  cameraId?: number;

  /**  */
  cameraPolicyId?: number;
}

export interface AssignAndActivateClassPolicyCommand {
  /**  */
  classId?: number;

  /**  */
  classPolicyId?: number;
}

export interface AssignAndActivateFamilyPolicyCommand {
  /**  */
  familyId?: number;

  /**  */
  familyPolicyId?: number;
}

export interface AssignAndActivateViewerPolicyCommand {
  /**  */
  viewerId?: number;

  /**  */
  viewerPolicyId?: number;
}

export interface AssignCamerasToClassCommand {
  /**  */
  id?: number;

  /**  */
  cameraIds?: number[];

  /**  */
  locationId?: number;
}

export interface AuthenticationProperties {
  /**  */
  items?: object;

  /**  */
  parameters?: object;

  /**  */
  isPersistent?: boolean;

  /**  */
  redirectUri?: string;

  /**  */
  issuedUtc?: string;

  /**  */
  expiresUtc?: string;

  /**  */
  allowRefresh?: boolean;
}

export interface BooleanApiResult {
  /**  */
  result?: boolean;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface BulkDeleteViewerRegistrationCommand {
  /**  */
  viewerIds?: number[];
}

export interface BulkEvaluateViewerRegistrationCommand {
  /**  */
  viewerIds?: number[];

  /**  */
  locationId?: number;

  /**  */
  isApproved?: boolean;
}

export interface BulkScheduleAutomatedApprovalCommand {
  /**  */
  locationId?: number;

  /**  */
  viewerIds?: number[];

  /**  */
  scheduledDate?: string;
}

export interface CacheFileResourceDto {
  /**  */
  guid?: string;

  /**  */
  fileName?: string;

  /**  */
  resourceLocator?: string;

  /**  */
  isPublic?: boolean;

  /**  */
  mimeType?: string;

  /**  */
  resourceProviderType?: ResourceProviderType;

  /**  */
  createdTimestamp?: string;

  /**  */
  deletedTimestamp?: string;
}

export interface CameraBlueIrisConfigDto {
  /**  */
  remoteAddress?: string;

  /**  */
  serverUsername?: string;

  /**  */
  serverPassword?: string;

  /**  */
  shortName?: string;

  /**  */
  currentMediaPlaylist?: CameraMediaPlaylistDto;

  /**  */
  hasActiveStreamRequests?: boolean;
}

export interface CameraBlueIrisConfigDtoApiResult {
  /**  */
  result?: CameraBlueIrisConfigDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CameraItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  classNames?: string[];

  /**  */
  thumbnailUrl?: string;

  /**  */
  staticImageUrl?: string;

  /**  */
  streamUrl?: string;

  /**  */
  encodedEventThumbnail?: string;

  /**  */
  clientAccessEnabled?: boolean;

  /**  */
  mobileIsEnabled?: boolean;

  /**  */
  streamShieldIsEnabled?: boolean;

  /**  */
  scheduleAllowsViewing?: boolean;

  /**  */
  isEventCamera?: boolean;
}

export interface CameraListDto {
  /**  */
  disabledStaticImageUrl?: string;

  /**  */
  disabledThumbnailImageUrl?: string;

  /**  */
  sleepStaticImageUrl?: string;

  /**  */
  sleepThumbnailImageUrl?: string;

  /**  */
  cameras?: CameraItemDto[];
}

export interface CameraListDtoApiResult {
  /**  */
  result?: CameraListDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CameraListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  isCameraPublic?: boolean;

  /**  */
  isPasswordProtected?: boolean;

  /**  */
  shortName?: string;

  /**  */
  thumbnailUrl?: string;

  /**  */
  policyName?: string;

  /**  */
  cameraPolicyId?: number;

  /**  */
  locationId?: number;

  /**  */
  isPolicyEnforced?: boolean;

  /**  */
  aiOnly?: boolean;
}

export interface CameraListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: CameraListItemDto[];
}

export interface CameraListItemDtoDataTablePageApiResult {
  /**  */
  result?: CameraListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CameraMediaPlaylistDto {
  /**  */
  id?: number;

  /**  */
  compatibilityVersion?: number;

  /**  */
  targetDurationSeconds?: number;

  /**  */
  mediaSequenceNumber?: number;

  /**  */
  cameraId?: number;

  /**  */
  cachedMediaPlaylist?: CacheFileResourceDto;

  /**  */
  mediaSegments?: MediaSegmentDto[];
}

export interface CameraPoliciesDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  cameraIds?: number[];

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface CameraPolicyCameraPolicyScheduleCameraPolicyScheduleEventCreatePolicyScheduleEventCommand {
  /**  */
  policyId?: number;

  /**  */
  daysOfWeek?: string[];

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface CameraPolicyCameraPolicyScheduleCameraPolicyScheduleEventEnableOrDisablePolicyCommand {
  /**  */
  id?: number;

  /**  */
  isEnabled?: boolean;
}

export interface CameraPolicyCameraPolicyScheduleCameraPolicyScheduleEventEnableOrDisablePolicyScheduleCommand {
  /**  */
  policyId?: number;

  /**  */
  isEnabled?: boolean;
}

export interface CameraPolicyCameraPolicyScheduleCameraPolicyScheduleEventUpdatePolicyScheduleEventCommand {
  /**  */
  id?: number;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface CameraPolicyConfigurationDto {
  /**  */
  id?: number;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface CameraPolicyConfigurationDtoApiResult {
  /**  */
  result?: CameraPolicyConfigurationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CameraPolicyDetailsDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface CameraPolicyDetailsDtoApiResult {
  /**  */
  result?: CameraPolicyDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CameraPolicyListItemConfigurationDto {
  /**  */
  scheduleRestrictionIsEnabled?: boolean;

  /**  */
  desktopAccessEnabledRestrictionIsEnabled?: boolean;

  /**  */
  mobileAccessEnabledRestrictionIsEnabled?: boolean;

  /**  */
  streamShieldEnabledRestrictionIsEnabled?: boolean;
}

export interface CameraPolicyListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  configuration?: CameraPolicyListItemConfigurationDto;

  /**  */
  assignedActiveCameraCount?: number;

  /**  */
  assignedInactiveCameraCount?: number;

  /**  */
  unassignedInactiveCameraCount?: number;
}

export interface CameraPolicyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: CameraPolicyListItemDto[];
}

export interface CameraPolicyListItemDtoDataTablePageApiResult {
  /**  */
  result?: CameraPolicyListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CameraPolicySummaryDto {
  /**  */
  isEnabled?: boolean;

  /**  */
  assignedActiveCameraCount?: number;

  /**  */
  assignedInactiveCameraCount?: number;

  /**  */
  assignedCameraCount?: number;

  /**  */
  unassignedCameraCount?: number;

  /**  */
  id?: number;

  /**  */
  locationId?: number;

  /**  */
  name?: string;
}

export interface CameraPolicySummaryDtoApiResult {
  /**  */
  result?: CameraPolicySummaryDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CameraSensitiveDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  shortName?: string;

  /**  */
  hasStreamUrl?: boolean;

  /**  */
  hasMotionJpgStreamUrl?: boolean;

  /**  */
  enableHlsProxy?: boolean;

  /**  */
  enableHlsCaching?: boolean;

  /**  */
  overrideStreamUrl?: boolean;

  /**  */
  hasThumbnailUrl?: boolean;

  /**  */
  overrideThumbnailUrl?: boolean;

  /**  */
  classIds?: number[];

  /**  */
  nvrServerId?: number;

  /**  */
  aiOnly?: boolean;
}

export interface CameraSensitiveDtoApiResult {
  /**  */
  result?: CameraSensitiveDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CameraStreamDto {
  /**  */
  streamUrl?: string;

  /**  */
  shortName?: string;

  /**  */
  nvrServerId?: number;
}

export interface CameraStreamDtoApiResult {
  /**  */
  result?: CameraStreamDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CameraThumbnailDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  thumbnailUrl?: string;
}

export interface CamerasByPolicyDataTableRequest {
  /**  */
  cameraPolicyId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface ChallengeResult {
  /**  */
  authenticationSchemes?: string[];

  /**  */
  properties?: AuthenticationProperties;
}

export interface ChangeEmailCommand {
  /**  */
  oldEmail?: string;

  /**  */
  newEmail?: string;

  /**  */
  currentPassword?: string;
}

export interface ChangeFamilyNameCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface ChangeFamilyPrimaryUserCommand {
  /**  */
  familyId?: number;

  /**  */
  primaryUserId?: number;
}

export interface ChangePasswordCommand {
  /**  */
  password?: string;

  /**  */
  newPassword?: string;

  /**  */
  confirmNewPassword?: string;
}

export interface ChildDeletionDetailsDto {
  /**  */
  id?: number;

  /**  */
  fullName?: string;

  /**  */
  familyId?: number;

  /**  */
  familyName?: string;

  /**  */
  onlyChild?: boolean;
}

export interface ChildDeletionDetailsDtoApiResult {
  /**  */
  result?: ChildDeletionDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ChildDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  fullName?: string;

  /**  */
  familyId?: number;

  /**  */
  classIds?: number[];
}

export interface ChildDtoApiResult {
  /**  */
  result?: ChildDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ChildForMerge {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  classes?: ClassForMerge[];
}

export interface ChildListItemDto {
  /**  */
  id?: number;

  /**  */
  lastName?: string;

  /**  */
  firstName?: string;

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  familyName?: string;

  /**  */
  familyViewerCount?: number;

  /**  */
  familyId?: number;

  /**  */
  approvalDate?: string;
}

export interface ChildListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ChildListItemDto[];
}

export interface ChildListItemDtoDataTablePageApiResult {
  /**  */
  result?: ChildListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ClassCameraDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  cameras?: CameraThumbnailDto[];

  /**  */
  areCamerasPublic?: boolean;
}

export interface ClassDetectionCountDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  adultCount?: number;

  /**  */
  childCount?: number;

  /**  */
  lastOccurenceUtc?: string;
}

export interface ClassDetectionCountDtoArrayApiResult {
  /**  */
  result?: ClassDetectionCountDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ClassDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  cameraIds?: number[];

  /**  */
  areCamerasPublic?: boolean;

  /**  */
  isPasswordProtected?: boolean;

  /**  */
  locationId?: number;
}

export interface ClassDtoApiResult {
  /**  */
  result?: ClassDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ClassForMerge {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface ClassListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  areCamerasPublic?: boolean;

  /**  */
  isPasswordProtected?: boolean;

  /**  */
  cameras?: CameraThumbnailDto[];

  /**  */
  policyName?: string;

  /**  */
  classPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;
}

export interface ClassListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ClassListItemDto[];
}

export interface ClassListItemDtoDataTablePageApiResult {
  /**  */
  result?: ClassListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ClassPoliciesDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface ClassPolicyClassPolicyScheduleClassPolicyScheduleEventCreatePolicyScheduleEventCommand {
  /**  */
  policyId?: number;

  /**  */
  daysOfWeek?: string[];

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface ClassPolicyClassPolicyScheduleClassPolicyScheduleEventEnableOrDisablePolicyCommand {
  /**  */
  id?: number;

  /**  */
  isEnabled?: boolean;
}

export interface ClassPolicyClassPolicyScheduleClassPolicyScheduleEventEnableOrDisablePolicyScheduleCommand {
  /**  */
  policyId?: number;

  /**  */
  isEnabled?: boolean;
}

export interface ClassPolicyClassPolicyScheduleClassPolicyScheduleEventUpdatePolicyScheduleEventCommand {
  /**  */
  id?: number;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface ClassPolicyConfigurationDto {
  /**  */
  id?: number;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface ClassPolicyConfigurationDtoApiResult {
  /**  */
  result?: ClassPolicyConfigurationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ClassPolicyDetailsDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface ClassPolicyDetailsDtoApiResult {
  /**  */
  result?: ClassPolicyDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ClassPolicyListItemConfigurationDto {
  /**  */
  scheduleRestrictionIsEnabled?: boolean;

  /**  */
  desktopAccessEnabledRestrictionIsEnabled?: boolean;

  /**  */
  mobileAccessEnabledRestrictionIsEnabled?: boolean;

  /**  */
  streamShieldEnabledRestrictionIsEnabled?: boolean;
}

export interface ClassPolicyListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  configuration?: ClassPolicyListItemConfigurationDto;

  /**  */
  assignedActiveClassCount?: number;

  /**  */
  assignedInactiveClassCount?: number;

  /**  */
  unassignedInactiveClassCount?: number;
}

export interface ClassPolicyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ClassPolicyListItemDto[];
}

export interface ClassPolicyListItemDtoDataTablePageApiResult {
  /**  */
  result?: ClassPolicyListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ClassPolicySummaryDto {
  /**  */
  isEnabled?: boolean;

  /**  */
  assignedActiveClassCount?: number;

  /**  */
  assignedInactiveClassCount?: number;

  /**  */
  assignedClassCount?: number;

  /**  */
  unassignedClassCount?: number;

  /**  */
  id?: number;

  /**  */
  locationId?: number;

  /**  */
  name?: string;
}

export interface ClassPolicySummaryDtoApiResult {
  /**  */
  result?: ClassPolicySummaryDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ClassesByPolicyDataTableRequest {
  /**  */
  classPolicyId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface ConfiguredNvrServerListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  domain?: string;

  /**  */
  nvrWanIpAddress?: string;

  /**  */
  nvrLanIpAddress?: string;
}

export interface ConfiguredNvrServerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ConfiguredNvrServerListItemDto[];
}

export interface ConfiguredNvrServerListItemDtoDataTablePageApiResult {
  /**  */
  result?: ConfiguredNvrServerListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ContactInformationDto {
  /**  */
  id?: number;

  /**  */
  description?: string;

  /**  */
  value?: string;

  /**  */
  isDeleted?: boolean;
}

export interface CreateCameraCommand {
  /**  */
  name?: string;

  /**  */
  shortName?: string;

  /**  */
  locationId?: number;

  /**  */
  nvrServerId?: number;

  /**  */
  aiOnly?: boolean;
}

export interface CreateCameraPolicyCommand {
  /**  */
  locationId?: number;

  /**  */
  cameraId?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface CreateChildCommand {
  /**  */
  familyId?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  classIds?: number[];
}

export interface CreateClassCommand {
  /**  */
  name?: string;

  /**  */
  cameraIds?: number[];

  /**  */
  locationId?: number;

  /**  */
  areCamerasPublic?: boolean;

  /**  */
  newCameraPassword?: string;
}

export interface CreateClassPolicyCommand {
  /**  */
  locationId?: number;

  /**  */
  classId?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface CreateEventCommand {
  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  eventDate?: string;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;

  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  childIds?: number[];

  /**  */
  cameraIds?: number[];

  /**  */
  thumbnailImage?: any;

  /**  */
  sendAnnouncement?: boolean;

  /**  */
  announcementNotificationDate?: string;

  /**  */
  announcementNotificationTime?: string;
}

export interface CreateFamilyPolicyCommand {
  /**  */
  locationId?: number;

  /**  */
  familyId?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface CreateFamilyRegistrationCommand {
  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  viewer?: ViewerDto;

  /**  */
  children?: ChildDto[];

  /**  */
  locationId?: number;
}

export interface CreateFamilyWithExistingViewerCommand {
  /**  */
  viewerId?: number;

  /**  */
  children?: ChildDto[];

  /**  */
  familyName?: string;

  /**  */
  locationId?: number;
}

export interface CreateInvitationDto {
  /**  */
  email?: string;

  /**  */
  viewerType?: string;
}

export interface CreateLicensePlateCommandDto {
  /**  */
  plateNumber?: string;

  /**  */
  viewerId?: number;
}

export interface CreateLocationCommand {
  /**  */
  name?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  website?: string;

  /**  */
  timeZoneId?: string;

  /**  */
  uptimeUrl?: string;

  /**  */
  address?: AddressDto;

  /**  */
  organizationId?: number;

  /**  */
  legacyId?: number;

  /**  */
  enterpriseLocationNumber?: number;

  /**  */
  isLprEnabled?: boolean;

  /**  */
  isLprNotificationsEnabled?: boolean;

  /**  */
  isAdminPortalCameraViewingOnsiteOnly?: boolean;

  /**  */
  isPublicCameraSupportEnabled?: boolean;

  /**  */
  overrideProgramType?: ProgramType;

  /**  */
  isEnterprise?: boolean;
}

export interface CreateLocationPolicyCommand {
  /**  */
  locationId?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface CreateNvrServerCommand {
  /**  */
  locationId?: number;

  /**  */
  id?: number;

  /**  */
  cameraIds?: number[];

  /**  */
  name?: string;

  /**  */
  domain?: string;

  /**  */
  nvrWanIpAddress?: string;

  /**  */
  nvrLanIpAddress?: string;

  /**  */
  serverUsername?: string;

  /**  */
  serverPassword?: string;

  /**  */
  cameraUsername?: string;

  /**  */
  cameraPassword?: string;
}

export interface CreateOrUpdateViewerSignInSessionCommand {
  /**  */
  userId?: number;

  /**  */
  sessionId?: string;
}

export interface CreateOrganizationCommand {
  /**  */
  name?: string;

  /**  */
  address?: AddressDto;

  /**  */
  pocName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  position?: string;

  /**  */
  programType?: string;
}

export interface CreateRegionCommand {
  /**  */
  organizationId?: number;

  /**  */
  name?: string;

  /**  */
  locationIds?: number[];
}

export interface CreateUserCommand {
  /**  */
  userName?: string;

  /**  */
  email?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRole?: number;

  /**  */
  locationIds?: number[];

  /**  */
  emailSubscriptions?: string[];

  /**  */
  smsSubscriptions?: string[];

  /**  */
  regionId?: number;

  /**  */
  organizationId?: number;

  /**  */
  hasPortalCameraAccess?: boolean;

  /**  */
  hasMobileCameraAccess?: boolean;

  /**  */
  hasAiDashboardAccess?: boolean;
}

export interface CreateViewerPolicyCommand {
  /**  */
  locationId?: number;

  /**  */
  viewerId?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface CreateViewerRegistrationCommand {
  /**  */
  inviteId?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  viewer?: ViewerDto;
}

export interface CreatedEntity {
  /**  */
  type?: string;

  /**  */
  id?: number;
}

export interface CurrentLocationDto {
  /**  */
  locationId?: number;

  /**  */
  timeZoneIana?: string;

  /**  */
  nvrServers?: number[];

  /**  */
  isIntegrationEnabled?: boolean;

  /**  */
  integrationClaims?: string[];
}

export interface CurrentLocationDtoApiResult {
  /**  */
  result?: CurrentLocationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DashboardActiveUserDto {
  /**  */
  viewerId?: number;

  /**  */
  fullName?: string;

  /**  */
  sessionDurationInSeconds?: number;
}

export interface DashboardActiveUsersDto {
  /**  */
  users?: DashboardActiveUserDto[];

  /**  */
  locationId?: number;

  /**  */
  requestDate?: string;
}

export interface DashboardActiveUsersDtoApiResult {
  /**  */
  result?: DashboardActiveUsersDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DashboardFamilyEngagementDto {
  /**  */
  engagementRate?: number;

  /**  */
  engagementPercentage?: number;

  /**  */
  roundedEngagementPercentage?: number;

  /**  */
  locationId?: number;

  /**  */
  requestDate?: string;
}

export interface DashboardFamilyEngagementDtoApiResult {
  /**  */
  result?: DashboardFamilyEngagementDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DashboardImage {
  /**  */
  caption?: string;

  /**  */
  linkUrl?: string;

  /**  */
  imageUrl?: string;
}

export interface DashboardImagesDto {
  /**  */
  images?: DashboardImage[];
}

export interface DashboardImagesDtoApiResult {
  /**  */
  result?: DashboardImagesDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DashboardNotificationsDto {
  /**  */
  notifications?: NotificationDto[];

  /**  */
  totalCount?: number;
}

export interface DashboardNotificationsDtoApiResult {
  /**  */
  result?: DashboardNotificationsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DashboardResource {
  /**  */
  label?: string;

  /**  */
  url?: string;

  /**  */
  pulse?: boolean;
}

export interface DashboardResourcesDto {
  /**  */
  resources?: DashboardResource[];

  /**  */
  locationId?: number;

  /**  */
  requestDate?: string;
}

export interface DashboardResourcesDtoApiResult {
  /**  */
  result?: DashboardResourcesDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DashboardTopUserDto {
  /**  */
  viewerId?: number;

  /**  */
  fullName?: string;

  /**  */
  signInCount?: number;

  /**  */
  signInRate?: number;

  /**  */
  signInPercentage?: number;

  /**  */
  roundedSignInPercentage?: number;
}

export interface DashboardTopUsersDto {
  /**  */
  users?: DashboardTopUserDto[];

  /**  */
  signInCount?: number;

  /**  */
  locationId?: number;

  /**  */
  requestDate?: string;
}

export interface DashboardTopUsersDtoApiResult {
  /**  */
  result?: DashboardTopUsersDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DashboardUserSummaryDto {
  /**  */
  familyCount?: number;

  /**  */
  viewerCount?: number;

  /**  */
  pendingRegistrationCount?: number;

  /**  */
  lockedViewerAccountsCount?: number;

  /**  */
  locationId?: number;

  /**  */
  requestDate?: string;
}

export interface DashboardUserSummaryDtoApiResult {
  /**  */
  result?: DashboardUserSummaryDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DataTableColumn {
  /**  */
  propertyName?: string;

  /**  */
  sortDirection?: string;

  /**  */
  searchTerm?: string;
}

export interface DataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface DateTimeRangeDto {
  /**  */
  startDate?: string;

  /**  */
  endDate?: string;
}

export interface DateTimeRangeOptionalDto {
  /**  */
  startDate?: string;

  /**  */
  endDate?: string;
}

export interface DebugApprovedFamilyResultDto {
  /**  */
  familyId?: number;
}

export interface DebugApprovedFamilyResultDtoApiResult {
  /**  */
  result?: DebugApprovedFamilyResultDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DebugCacheCameraStreamMessageCommand {
  /**  */
  cameraId?: number;
}

export interface DebugChangeIntegrationLocationExternalIdCommand {
  /**  */
  locationId?: number;

  /**  */
  locationExternalId?: string;
}

export interface DebugCreateApprovedFamilyCommand {
  /**  */
  familyName?: string;

  /**  */
  primaryUserName?: string;

  /**  */
  primaryEmail?: string;

  /**  */
  locationId?: number;

  /**  */
  featureFlags?: FeatureFlagType[];
}

export interface DebugCreateIntegrationEnrollmentImportLogCommand {
  /**  */
  locationId?: number;

  /**  */
  importType?: string;
}

export interface DebugNvrUserDto {
  /**  */
  plainTextUsername?: string;

  /**  */
  plainTextPassword?: string;
}

export interface DebugNvrUserDtoListApiResult {
  /**  */
  result?: DebugNvrUserDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DebugPushNotificationCommand {
  /**  */
  userId?: number;

  /**  */
  title?: string;

  /**  */
  body?: string;

  /**  */
  featureFlags?: FeatureFlagType[];
}

export interface DebugScoutxAddCameraCommand {
  /**  */
  cameraId?: number;

  /**  */
  streamUrl?: string;
}

export interface DebugScoutxRemoveCameraCommand {
  /**  */
  cameraId?: number;
}

export interface DebugSendBlueIrisWebhookCommand {
  /**  */
  locationId?: number;

  /**  */
  licensePlate?: string;
}

export interface DebugSendScoutXWebhookCommand {
  /**  */
  cameraId?: number;

  /**  */
  isDetectionCount?: boolean;

  /**  */
  adultCount?: number;

  /**  */
  childCount?: number;

  /**  */
  base64Img?: string;
}

export interface DeleteEntityDetailsDto {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface DeleteEntityDetailsDtoApiResult {
  /**  */
  result?: DeleteEntityDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DeleteViewerCommand {
  /**  */
  id?: number;

  /**  */
  newPrimaryUserId?: number;

  /**  */
  shouldDeleteFamily?: boolean;
}

export interface DeleteViewerRegistrationCommand {
  /**  */
  viewerId?: number;
}

export interface DeniedRegistrationListItemDto {
  /**  */
  approvedOrDeniedDate?: string;

  /**  */
  children?: string[];

  /**  */
  name?: string;

  /**  */
  userName?: string;

  /**  */
  viewerType?: string;

  /**  */
  familyName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  policyName?: string;

  /**  */
  familyPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;

  /**  */
  locationId?: number;

  /**  */
  familyId?: number;

  /**  */
  id?: number;

  /**  */
  hasSuggestedFamilyMerge?: boolean;

  /**  */
  familyIsApproved?: string;

  /**  */
  type?: string;
}

export interface DeniedRegistrationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: DeniedRegistrationListItemDto[];
}

export interface DeniedRegistrationListItemDtoDataTablePageApiResult {
  /**  */
  result?: DeniedRegistrationListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DesktopAccessEnabledConfigurationItemDto {
  /**  */
  value?: boolean;

  /**  */
  isEnabled?: boolean;
}

export interface DoubleApiResult {
  /**  */
  result?: number;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface EnableOrDisableLocationPolicyCommand {
  /**  */
  id?: number;

  /**  */
  isEnabled?: boolean;
}

export interface EvaluateRoomRequestCommand {
  /**  */
  roomRequestId?: number;

  /**  */
  isApproved?: boolean;

  /**  */
  transferNow?: boolean;

  /**  */
  scheduleClassesCommand?: ScheduleClassesCommand;
}

export interface EvaluateViewerRegistrationCommand {
  /**  */
  viewerId?: number;

  /**  */
  isApproved?: boolean;
}

export interface EventDeleteDetailsDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  isEventOngoing?: boolean;
}

export interface EventDeleteDetailsDtoApiResult {
  /**  */
  result?: EventDeleteDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface EventDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  eventDate?: string;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;

  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  childIds?: number[];

  /**  */
  childrenOptionItems?: OptionItemDto[];

  /**  */
  cameraIds?: number[];

  /**  */
  thumbnailImageUrl?: string;

  /**  */
  isEventOngoing?: boolean;
}

export interface EventDtoApiResult {
  /**  */
  result?: EventDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface EventListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  classNames?: string[];

  /**  */
  childNames?: string[];

  /**  */
  cameras?: CameraThumbnailDto[];

  /**  */
  thumbnailImage?: FileResource;

  /**  */
  thumbnailImageUrl?: string;
}

export interface EventListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: EventListItemDto[];
}

export interface EventListItemDtoDataTablePageApiResult {
  /**  */
  result?: EventListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface EventOrAutomationListItemDto {
  /**  */
  isEvent?: boolean;

  /**  */
  id?: number;

  /**  */
  type?: ScheduledAutomationType;

  /**  */
  scheduledDate?: string;

  /**  */
  scheduleInformativeMessage?: string;
}

export interface EventOrAutomationListItemDtoIEnumerableApiResult {
  /**  */
  result?: EventOrAutomationListItemDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface EventSendEmailNotificationsDetailsDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  viewerEmailCount?: number;
}

export interface EventSendEmailNotificationsDetailsDtoApiResult {
  /**  */
  result?: EventSendEmailNotificationsDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ExternalNvrLinksDto {
  /**  */
  nvrWanIpAddress?: string;

  /**  */
  nvrLanIpAddress?: string;

  /**  */
  nvrName?: string;

  /**  */
  isClientOnsite?: boolean;

  /**  */
  isAdminPortalCameraViewingOnsiteOnly?: boolean;
}

export interface ExternalNvrLinksDtoApiResult {
  /**  */
  result?: ExternalNvrLinksDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FamiliesByPolicyDataTableRequest {
  /**  */
  familyPolicyId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface FamilyChildListItemDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  classes?: ClassCameraDto[];
}

export interface FamilyChildListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: FamilyChildListItemDto[];
}

export interface FamilyChildListItemDtoDataTablePageApiResult {
  /**  */
  result?: FamilyChildListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FamilyDetailsForMergeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  viewers?: ViewerForMerge[];

  /**  */
  children?: ChildForMerge[];

  /**  */
  policies?: PolicyForMerge[];

  /**  */
  isApproved?: boolean;
}

export interface FamilyDetailsForMergeDtoApiResult {
  /**  */
  result?: FamilyDetailsForMergeDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FamilyDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  primaryUserId?: number;

  /**  */
  isApproved?: boolean;
}

export interface FamilyDtoApiResult {
  /**  */
  result?: FamilyDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FamilyListItemDto {
  /**  */
  id?: number;

  /**  */
  locationId?: number;

  /**  */
  name?: string;

  /**  */
  familyViewerCount?: number;

  /**  */
  familyId?: number;

  /**  */
  children?: string[];

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  policyName?: string;

  /**  */
  familyPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;
}

export interface FamilyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: FamilyListItemDto[];
}

export interface FamilyListItemDtoDataTablePageApiResult {
  /**  */
  result?: FamilyListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FamilyMemberTransferDetailsDto {
  /**  */
  id?: number;

  /**  */
  fullName?: string;

  /**  */
  isPrimaryUser?: boolean;

  /**  */
  oldFamilyId?: number;

  /**  */
  oldFamilyName?: string;

  /**  */
  viewers?: OptionItemDto[];

  /**  */
  families?: OptionItemDto[];
}

export interface FamilyMemberTransferDetailsDtoApiResult {
  /**  */
  result?: FamilyMemberTransferDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FamilyPoliciesDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  familyIds?: number[];

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface FamilyPolicyConfigurationDto {
  /**  */
  id?: number;

  /**  */
  viewerCameraSessionMaxCountRestriction?: ViewerCameraSessionMaxCountConfigurationItemDto;

  /**  */
  viewerCameraSessionMaxLengthRestriction?: ViewerCameraSessionMaxLengthConfigurationItemDto;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface FamilyPolicyConfigurationDtoApiResult {
  /**  */
  result?: FamilyPolicyConfigurationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FamilyPolicyDetailsDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface FamilyPolicyDetailsDtoApiResult {
  /**  */
  result?: FamilyPolicyDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FamilyPolicyFamilyPolicyScheduleFamilyPolicyScheduleEventCreatePolicyScheduleEventCommand {
  /**  */
  policyId?: number;

  /**  */
  daysOfWeek?: string[];

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface FamilyPolicyFamilyPolicyScheduleFamilyPolicyScheduleEventEnableOrDisablePolicyCommand {
  /**  */
  id?: number;

  /**  */
  isEnabled?: boolean;
}

export interface FamilyPolicyFamilyPolicyScheduleFamilyPolicyScheduleEventEnableOrDisablePolicyScheduleCommand {
  /**  */
  policyId?: number;

  /**  */
  isEnabled?: boolean;
}

export interface FamilyPolicyFamilyPolicyScheduleFamilyPolicyScheduleEventUpdatePolicyScheduleEventCommand {
  /**  */
  id?: number;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface FamilyPolicyListItemConfigurationDto {
  /**  */
  scheduleRestrictionIsEnabled?: boolean;

  /**  */
  viewerCameraSessionMaxCountRestrictionIsEnabled?: boolean;

  /**  */
  viewerCameraSessionMaxLengthRestrictionIsEnabled?: boolean;

  /**  */
  desktopAccessEnabledRestrictionIsEnabled?: boolean;

  /**  */
  mobileAccessEnabledRestrictionIsEnabled?: boolean;

  /**  */
  streamShieldEnabledRestrictionIsEnabled?: boolean;
}

export interface FamilyPolicyListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  configuration?: FamilyPolicyListItemConfigurationDto;

  /**  */
  assignedActiveFamilyCount?: number;

  /**  */
  assignedInactiveFamilyCount?: number;

  /**  */
  unassignedInactiveFamilyCount?: number;
}

export interface FamilyPolicyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: FamilyPolicyListItemDto[];
}

export interface FamilyPolicyListItemDtoDataTablePageApiResult {
  /**  */
  result?: FamilyPolicyListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FamilyPolicySummaryDto {
  /**  */
  isEnabled?: boolean;

  /**  */
  assignedActiveFamilyCount?: number;

  /**  */
  assignedInactiveFamilyCount?: number;

  /**  */
  assignedFamilyCount?: number;

  /**  */
  unassignedFamilyCount?: number;

  /**  */
  id?: number;

  /**  */
  locationId?: number;

  /**  */
  name?: string;
}

export interface FamilyPolicySummaryDtoApiResult {
  /**  */
  result?: FamilyPolicySummaryDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FeatureFlagDto {
  /**  */
  id?: number;

  /**  */
  type?: FeatureFlagType;

  /**  */
  enabled?: boolean;
}

export interface FeatureFlagDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: FeatureFlagDto[];
}

export interface FeatureFlagDtoDataTablePageApiResult {
  /**  */
  result?: FeatureFlagDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FeatureFlagTypeListApiResult {
  /**  */
  result?: FeatureFlagType[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FileResource {
  /**  */
  id?: number;

  /**  */
  guid?: string;

  /**  */
  mimeType?: string;

  /**  */
  fileName?: string;

  /**  */
  resourceLocator?: string;

  /**  */
  isPublic?: boolean;

  /**  */
  resourceProviderType?: ResourceProviderType;

  /**  */
  createdTimestamp?: string;

  /**  */
  deletedTimestamp?: string;
}

export interface ForgotPasswordCommand {
  /**  */
  userName?: string;
}

export interface ForgotUsernameNonViewerCommand {
  /**  */
  email?: string;
}

export interface GeneralSettingsDto {
  /**  */
  baseUrl?: string;

  /**  */
  webViewerUrl?: string;

  /**  */
  supportCenterUrl?: string;
}

export interface GeneralSettingsDtoApiResult {
  /**  */
  result?: GeneralSettingsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface GetActiveViewersDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  childName?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetApprovedChildrenByLocationIdTableRequest {
  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetApprovedFamiliesByLocationIdRequest {
  /**  */
  classIds?: number[];

  /**  */
  childName?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetClassOrFamilyChildrenQuery {
  /**  */
  classIds?: number[];

  /**  */
  familyIds?: number[];
}

export interface GetConfiguredNvrServersByLocationDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetDeniedRegistrationsDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  childName?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetEventsAndAutomationsQuery {
  /**  */
  locationId?: number;

  /**  */
  take?: number;
}

export interface GetFamilyOptionsForMergeQuery {
  /**  */
  locationId?: number;

  /**  */
  firstFamilyId?: number;
}

export interface GetFamilyOptionsQueryDto {
  /**  */
  value?: number;

  /**  */
  label?: string;

  /**  */
  isSuggestedMerge?: boolean;
}

export interface GetFamilyOptionsQueryDtoListApiResult {
  /**  */
  result?: GetFamilyOptionsQueryDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface GetFutureEventsByLocationIdDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  dateTimeRange?: DateTimeRangeDto;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetInactiveViewersDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  childName?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetNvrServerCameraDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetNvrServerDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetOrganizationLocationsDataTableRequest {
  /**  */
  regionId?: number;

  /**  */
  organizationId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetOrganizationUsersDataTableRequest {
  /**  */
  organizationId?: number;

  /**  */
  locationId?: number;

  /**  */
  regionId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetPastEventsByLocationIdDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  dateTimeRange?: DateTimeRangeOptionalDto;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetPendingRegistrationsDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  childName?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetRegionDataTableRequest {
  /**  */
  organizationId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetRoomRequestsDataTableQueryRequest {
  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  childName?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetScheduledAnimationsDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetScheduledClassesDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  scheduledDate?: string;

  /**  */
  classIds?: number[];

  /**  */
  childName?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetScheduledRegistrationsDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  scheduledApprovalDate?: string;

  /**  */
  classIds?: number[];

  /**  */
  childName?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetScheduledViewerDeletionsDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  scheduledDate?: string;

  /**  */
  classIds?: number[];

  /**  */
  childName?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetUsersDataTableRequest {
  /**  */
  organizationId?: number;

  /**  */
  locationId?: number;

  /**  */
  regionId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetViewerCameraSessionsByViewerDataTableRequest {
  /**  */
  sessionDate?: string;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetViewersByFamilyDataTableRequest {
  /**  */
  familyId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GuardianMaxCountConfigurationItemDto {
  /**  */
  value?: number;

  /**  */
  isEnabled?: boolean;
}

export interface GuidNullableApiResult {
  /**  */
  result?: string;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface InactiveViewerListItemDto {
  /**  */
  lockoutEnd?: string;

  /**  */
  fullName?: string;

  /**  */
  userName?: string;

  /**  */
  isLockedOut?: boolean;

  /**  */
  isScheduledForDeletion?: boolean;

  /**  */
  children?: string[];

  /**  */
  viewerType?: string;

  /**  */
  familyName?: string;

  /**  */
  familyId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  viewerPolicyName?: string;

  /**  */
  viewerPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;

  /**  */
  scheduledViewerDeletionId?: number;

  /**  */
  scheduledDate?: string;

  /**  */
  isPrimaryUser?: boolean;

  /**  */
  id?: number;
}

export interface InactiveViewerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: InactiveViewerListItemDto[];
}

export interface InactiveViewerListItemDtoDataTablePageApiResult {
  /**  */
  result?: InactiveViewerListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface Int32ApiResult {
  /**  */
  result?: number;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface Int32ListApiResult {
  /**  */
  result?: number[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface Int32NullableApiResult {
  /**  */
  result?: number;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface IntegratedLocationCamera {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface IntegratedLocationCameraICollectionApiResult {
  /**  */
  result?: IntegratedLocationCamera[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface IntegratedViewerInvitationDto {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;
}

export interface IntegratedViewerInvitationDtoApiResult {
  /**  */
  result?: IntegratedViewerInvitationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface IntegrationDownShowAllCamerasEnabledConfigurationItemDto {
  /**  */
  value?: boolean;
}

export interface IntegrationListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface IntegrationListItemDtoApiResult {
  /**  */
  result?: IntegrationListItemDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface IntegrationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: IntegrationListItemDto[];
}

export interface IntegrationListItemDtoDataTablePageApiResult {
  /**  */
  result?: IntegrationListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface IntegrationNvrSessionDto {
  /**  */
  serverUrl?: string;

  /**  */
  sessionToken?: string;

  /**  */
  thumbnailUrl?: string;
}

export interface IntegrationNvrSessionDtoApiResult {
  /**  */
  result?: IntegrationNvrSessionDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface IntegrationPolicyConfigurationDto {
  /**  */
  id?: number;

  /**  */
  viewerCameraSessionMaxCountRestriction?: ViewerCameraSessionMaxCountConfigurationItemDto;

  /**  */
  viewerCameraSessionMaxLengthRestriction?: ViewerCameraSessionMaxLengthConfigurationItemDto;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  integrationDownShowAllCamerasEnabledRestriction?: IntegrationDownShowAllCamerasEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface IntegrationPolicyConfigurationDtoApiResult {
  /**  */
  result?: IntegrationPolicyConfigurationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface IntegrationPolicyIntegrationPolicyScheduleIntegrationPolicyScheduleEventCreatePolicyScheduleEventCommand {
  /**  */
  policyId?: number;

  /**  */
  daysOfWeek?: string[];

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface IntegrationPolicyIntegrationPolicyScheduleIntegrationPolicyScheduleEventEnableOrDisablePolicyScheduleCommand {
  /**  */
  policyId?: number;

  /**  */
  isEnabled?: boolean;
}

export interface IntegrationPolicyIntegrationPolicyScheduleIntegrationPolicyScheduleEventUpdatePolicyScheduleEventCommand {
  /**  */
  id?: number;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface InvalidateViewerCameraSessionsFromTodayByFamilyCommand {
  /**  */
  familyId?: number;
}

export interface InvalidateViewerCameraSessionsFromTodayByViewerCommand {
  /**  */
  viewerId?: number;
}

export interface InvitationStatus {
  /**  */
  inviteId?: string;

  /**  */
  invalidReason?: string;

  /**  */
  isValid?: boolean;
}

export interface InvitationStatusApiResult {
  /**  */
  result?: InvitationStatus;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface InviteViewersToFamilyCommand {
  /**  */
  familyId?: number;

  /**  */
  invitees?: CreateInvitationDto[];
}

export interface LicensePlateDto {
  /**  */
  id?: number;

  /**  */
  plateNumber?: string;

  /**  */
  viewerOptionItem?: OptionItemDto;
}

export interface LicensePlateDtoApiResult {
  /**  */
  result?: LicensePlateDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LicensePlateListItemDto {
  /**  */
  id?: number;

  /**  */
  plateNumber?: string;

  /**  */
  viewer?: string;

  /**  */
  userName?: string;
}

export interface LicensePlateListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: LicensePlateListItemDto[];
}

export interface LicensePlateListItemDtoDataTablePageApiResult {
  /**  */
  result?: LicensePlateListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LiveSitterConfigurationDto {
  /**  */
  liveSitterLocationId?: string;
}

export interface LiveSitterSettingsDto {
  /**  */
  aiDashboardUrl?: string;

  /**  */
  aiIntegrationsInfoUrl?: string;
}

export interface LiveSitterSettingsDtoApiResult {
  /**  */
  result?: LiveSitterSettingsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationAiPartnerConfigurationsDto {
  /**  */
  id?: number;

  /**  */
  liveSitterConfiguration?: LiveSitterConfigurationDto;
}

export interface LocationAiPartnerConfigurationsDtoApiResult {
  /**  */
  result?: LocationAiPartnerConfigurationsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  website?: string;

  /**  */
  timeZoneId?: string;

  /**  */
  uptimeUrl?: string;

  /**  */
  address?: AddressDto;

  /**  */
  userIds?: number[];

  /**  */
  organizationId?: number;

  /**  */
  legacyId?: number;

  /**  */
  enterpriseLocationNumber?: number;

  /**  */
  isLprEnabled?: boolean;

  /**  */
  isLprNotificationsEnabled?: boolean;

  /**  */
  isAdminPortalCameraViewingOnsiteOnly?: boolean;

  /**  */
  publicId?: string;

  /**  */
  liveSitterLocationId?: string;

  /**  */
  overrideProgramType?: ProgramType;

  /**  */
  isEnterprise?: boolean;
}

export interface LocationDtoApiResult {
  /**  */
  result?: LocationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationIntegrationEnrollmentDto {
  /**  */
  integrationEnrollmentId?: number;

  /**  */
  integrationName?: string;

  /**  */
  status?: EnrollmentStatus;

  /**  */
  hasLocationKey?: boolean;

  /**  */
  integrationPolicyId?: number;
}

export interface LocationIntegrationEnrollmentDtoApiResult {
  /**  */
  result?: LocationIntegrationEnrollmentDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationListItemDto {
  /**  */
  id?: number;

  /**  */
  legacyId?: number;

  /**  */
  name?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  website?: string;

  /**  */
  policyName?: string;

  /**  */
  locationPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;

  /**  */
  isIntegrationPolicy?: boolean;
}

export interface LocationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: LocationListItemDto[];
}

export interface LocationListItemDtoDataTablePageApiResult {
  /**  */
  result?: LocationListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationNotificationSettingsDto {
  /**  */
  isLprNotificationsEnabled?: boolean;
}

export interface LocationNotificationSettingsDtoApiResult {
  /**  */
  result?: LocationNotificationSettingsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationNvrServerCountDto {
  /**  */
  count?: number;

  /**  */
  singleServerId?: number;
}

export interface LocationNvrServerCountDtoApiResult {
  /**  */
  result?: LocationNvrServerCountDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationOptionItemDto {
  /**  */
  legacyId?: number;

  /**  */
  isIntegratedLocation?: boolean;

  /**  */
  value?: number;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;

  /**  */
  isDisabled?: boolean;
}

export interface LocationOptionItemDtoListApiResult {
  /**  */
  result?: LocationOptionItemDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationPolicyConfigurationDto {
  /**  */
  id?: number;

  /**  */
  guardianMaxCountRestriction?: GuardianMaxCountConfigurationItemDto;

  /**  */
  relativeMaxCountRestriction?: RelativeMaxCountConfigurationItemDto;

  /**  */
  viewerCameraSessionMaxCountRestriction?: ViewerCameraSessionMaxCountConfigurationItemDto;

  /**  */
  viewerCameraSessionMaxLengthRestriction?: ViewerCameraSessionMaxLengthConfigurationItemDto;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface LocationPolicyConfigurationDtoApiResult {
  /**  */
  result?: LocationPolicyConfigurationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationPolicyDetailsDto {
  /**  */
  id?: number;

  /**  */
  isDefault?: boolean;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface LocationPolicyDetailsDtoApiResult {
  /**  */
  result?: LocationPolicyDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationPolicyListItemConfigurationDto {
  /**  */
  scheduleRestrictionIsEnabled?: boolean;

  /**  */
  guardianMaxCountRestrictionIsEnabled?: boolean;

  /**  */
  relativeMaxCountRestrictionIsEnabled?: boolean;

  /**  */
  viewerCameraSessionMaxCountRestrictionIsEnabled?: boolean;

  /**  */
  viewerCameraSessionMaxLengthRestrictionIsEnabled?: boolean;

  /**  */
  desktopAccessEnabledRestrictionIsEnabled?: boolean;

  /**  */
  mobileAccessEnabledRestrictionIsEnabled?: boolean;

  /**  */
  streamShieldEnabledRestrictionIsEnabled?: boolean;
}

export interface LocationPolicyListItemDto {
  /**  */
  id?: number;

  /**  */
  isDefault?: boolean;

  /**  */
  isEnabled?: boolean;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  configuration?: LocationPolicyListItemConfigurationDto;

  /**  */
  isActive?: boolean;
}

export interface LocationPolicyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: LocationPolicyListItemDto[];
}

export interface LocationPolicyListItemDtoDataTablePageApiResult {
  /**  */
  result?: LocationPolicyListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationPolicyLocationPolicyScheduleLocationPolicyScheduleEventCreatePolicyScheduleEventCommand {
  /**  */
  policyId?: number;

  /**  */
  daysOfWeek?: string[];

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface LocationPolicyLocationPolicyScheduleLocationPolicyScheduleEventEnableOrDisablePolicyScheduleCommand {
  /**  */
  policyId?: number;

  /**  */
  isEnabled?: boolean;
}

export interface LocationPolicyLocationPolicyScheduleLocationPolicyScheduleEventUpdatePolicyScheduleEventCommand {
  /**  */
  id?: number;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface LocationPolicySummaryDto {
  /**  */
  isActive?: boolean;

  /**  */
  isDefault?: boolean;

  /**  */
  isEnabled?: boolean;

  /**  */
  id?: number;

  /**  */
  locationId?: number;

  /**  */
  name?: string;
}

export interface LocationPolicySummaryDtoApiResult {
  /**  */
  result?: LocationPolicySummaryDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationRequestCountDto {
  /**  */
  registrationCount?: number;

  /**  */
  roomRequestCount?: number;
}

export interface LocationRequestCountDtoApiResult {
  /**  */
  result?: LocationRequestCountDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LocationUserListItemDto {
  /**  */
  userName?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  role?: string;

  /**  */
  id?: number;

  /**  */
  isLockedOut?: boolean;

  /**  */
  isReadOnly?: boolean;
}

export interface LocationUserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: LocationUserListItemDto[];
}

export interface LocationUserListItemDtoDataTablePageApiResult {
  /**  */
  result?: LocationUserListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ManageViewerAccountCommand {
  /**  */
  plateNumber?: string;
}

export interface MarketplaceIntegrationDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  isEnrolled?: boolean;

  /**  */
  canBeEnabled?: boolean;

  /**  */
  partnerLogoRelativeUrl?: string;

  /**  */
  absolutePartnerUrl?: string;

  /**  */
  absoluteKbaUrl?: string;
}

export interface MarketplaceIntegrationDtoIEnumerableApiResult {
  /**  */
  result?: MarketplaceIntegrationDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface MediaSegmentDto {
  /**  */
  id?: number;

  /**  */
  durationSeconds?: number;

  /**  */
  title?: string;

  /**  */
  sequenceNumber?: number;

  /**  */
  originalSegmentUrl?: string;

  /**  */
  cacheFileResource?: CacheFileResourceDto;

  /**  */
  uri?: string;
}

export interface MergeApprovedFamiliesCommand {
  /**  */
  firstFamilyId?: number;

  /**  */
  secondFamilyId?: number;

  /**  */
  primaryUserId?: number;

  /**  */
  activePolicyId?: number;

  /**  */
  selectedNameFamilyId?: number;
}

export interface MergeApprovedIntoUnapprovedFamilyCommand {
  /**  */
  firstFamilyId?: number;

  /**  */
  secondFamilyId?: number;

  /**  */
  primaryUserId?: number;

  /**  */
  selectedNameFamilyId?: number;
}

export interface MergeUnapprovedFamiliesCommand {
  /**  */
  firstFamilyId?: number;

  /**  */
  secondFamilyId?: number;

  /**  */
  primaryUserId?: number;

  /**  */
  selectedNameFamilyId?: number;

  /**  */
  mergeAction?: MergeAction;

  /**  */
  scheduledDate?: string;
}

export interface MobileAccessEnabledConfigurationItemDto {
  /**  */
  value?: boolean;

  /**  */
  isEnabled?: boolean;
}

export interface MobileLinksDto {
  /**  */
  appStoreLink?: string;

  /**  */
  playStoreLink?: string;
}

export interface MobileLinksDtoApiResult {
  /**  */
  result?: MobileLinksDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface NotificationDto {
  /**  */
  message?: string;

  /**  */
  url?: string;
}

export interface NotificationPreferencesDto {
  /**  */
  emailSubscriptions?: string[];

  /**  */
  smsSubscriptions?: string[];

  /**  */
  phoneNumberConfirmed?: boolean;
}

export interface NotificationPreferencesDtoApiResult {
  /**  */
  result?: NotificationPreferencesDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface NvrServerListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  domain?: string;

  /**  */
  nvrWanIpAddress?: string;

  /**  */
  nvrLanIpAddress?: string;
}

export interface NvrServerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: NvrServerListItemDto[];
}

export interface NvrServerListItemDtoDataTablePageApiResult {
  /**  */
  result?: NvrServerListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface NvrServerNavigationDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  locationId?: number;
}

export interface NvrServerNavigationDtoListApiResult {
  /**  */
  result?: NvrServerNavigationDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface NvrServerSelectionDto {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface NvrServerSensitiveDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  domain?: string;

  /**  */
  nvrWanIpAddress?: string;

  /**  */
  nvrLanIpAddress?: string;

  /**  */
  blueIrisJwt?: string;

  /**  */
  hasServerUsername?: boolean;

  /**  */
  hasServerPassword?: boolean;

  /**  */
  hasCameraUsername?: boolean;

  /**  */
  hasCameraPassword?: boolean;

  /**  */
  cameraIds?: number[];
}

export interface NvrServerSensitiveDtoApiResult {
  /**  */
  result?: NvrServerSensitiveDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OAuthApprovalDto {
  /**  */
  clientId?: string;

  /**  */
  scopes?: string[];
}

export interface OAuthClientDto {
  /**  */
  id?: string;

  /**  */
  displayName?: string;

  /**  */
  clientId?: string;

  /**  */
  clientType?: string;
}

export interface OAuthClientDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: OAuthClientDto[];
}

export interface OAuthClientDtoDataTablePageApiResult {
  /**  */
  result?: OAuthClientDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OAuthClientSecretDto {
  /**  */
  id?: string;

  /**  */
  secret?: string;
}

export interface OAuthClientSecretDtoApiResult {
  /**  */
  result?: OAuthClientSecretDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OptionItemDto {
  /**  */
  value?: number;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;

  /**  */
  isDisabled?: boolean;
}

export interface OptionItemDtoIEnumerableApiResult {
  /**  */
  result?: OptionItemDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OptionItemDtoListApiResult {
  /**  */
  result?: OptionItemDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OrganizationDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  address?: AddressDto;

  /**  */
  pocName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  position?: string;

  /**  */
  programType?: string;

  /**  */
  hasEnterpriseLocations?: boolean;
}

export interface OrganizationDtoApiResult {
  /**  */
  result?: OrganizationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OrganizationListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  firstLocationName?: string;

  /**  */
  locationCount?: number;

  /**  */
  locationNames?: string[];

  /**  */
  hq?: string;

  /**  */
  pointOfContact?: PointOfContact;

  /**  */
  program?: ProgramType;

  /**  */
  regions?: string;

  /**  */
  regionCount?: number;
}

export interface OrganizationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: OrganizationListItemDto[];
}

export interface OrganizationListItemDtoDataTablePageApiResult {
  /**  */
  result?: OrganizationListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OrganizationLocationsListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  website?: string;

  /**  */
  policyName?: string;

  /**  */
  locationPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;

  /**  */
  isIntegrationPolicy?: boolean;

  /**  */
  regions?: string[];
}

export interface OrganizationLocationsListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: OrganizationLocationsListItemDto[];
}

export interface OrganizationLocationsListItemDtoDataTablePageApiResult {
  /**  */
  result?: OrganizationLocationsListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OrganizationUserListItemDto {
  /**  */
  userName?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  role?: string;

  /**  */
  locations?: string[];

  /**  */
  region?: string;

  /**  */
  id?: number;

  /**  */
  isLockedOut?: boolean;

  /**  */
  isReadOnly?: boolean;
}

export interface OrganizationUserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: OrganizationUserListItemDto[];
}

export interface OrganizationUserListItemDtoDataTablePageApiResult {
  /**  */
  result?: OrganizationUserListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PasswordLoginCommand {
  /**  */
  userName?: string;

  /**  */
  password?: string;

  /**  */
  rememberMe?: boolean;
}

export interface PastEventListItemDto {
  /**  */
  id?: number;

  /**  */
  date?: string;

  /**  */
  startDate?: string;

  /**  */
  endDate?: string;

  /**  */
  name?: string;

  /**  */
  classNames?: string[];

  /**  */
  childNames?: string[];

  /**  */
  cameras?: CameraThumbnailDto[];
}

export interface PastEventListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PastEventListItemDto[];
}

export interface PastEventListItemDtoDataTablePageApiResult {
  /**  */
  result?: PastEventListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PendingRegistrationListItemDto {
  /**  */
  createdDate?: string;

  /**  */
  children?: string[];

  /**  */
  name?: string;

  /**  */
  userName?: string;

  /**  */
  viewerType?: string;

  /**  */
  familyName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  policyName?: string;

  /**  */
  familyPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;

  /**  */
  locationId?: number;

  /**  */
  familyId?: number;

  /**  */
  id?: number;

  /**  */
  hasSuggestedFamilyMerge?: boolean;

  /**  */
  familyIsApproved?: string;

  /**  */
  type?: string;
}

export interface PendingRegistrationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PendingRegistrationListItemDto[];
}

export interface PendingRegistrationListItemDtoDataTablePageApiResult {
  /**  */
  result?: PendingRegistrationListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PointOfContact {
  /**  */
  name?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;
}

export interface PolicyCameraListItemDto {
  /**  */
  id?: number;

  /**  */
  status?: PolicyStatus;

  /**  */
  name?: string;
}

export interface PolicyCameraListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PolicyCameraListItemDto[];
}

export interface PolicyCameraListItemDtoDataTablePageApiResult {
  /**  */
  result?: PolicyCameraListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PolicyClassListItemDto {
  /**  */
  id?: number;

  /**  */
  status?: PolicyStatus;

  /**  */
  name?: string;
}

export interface PolicyClassListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PolicyClassListItemDto[];
}

export interface PolicyClassListItemDtoDataTablePageApiResult {
  /**  */
  result?: PolicyClassListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PolicyFamilyListItemDto {
  /**  */
  id?: number;

  /**  */
  status?: PolicyStatus;

  /**  */
  name?: string;

  /**  */
  children?: string[];

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  policyName?: string;

  /**  */
  familyPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;
}

export interface PolicyFamilyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PolicyFamilyListItemDto[];
}

export interface PolicyFamilyListItemDtoDataTablePageApiResult {
  /**  */
  result?: PolicyFamilyListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PolicyForMerge {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PolicyScheduleDto {
  /**  */
  id?: number;

  /**  */
  isEnabled?: boolean;
}

export interface PolicyScheduleDtoApiResult {
  /**  */
  result?: PolicyScheduleDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PolicyScheduleEventDto {
  /**  */
  id?: number;

  /**  */
  dayOfWeek?: DayOfWeek;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface PolicyScheduleEventDtoListApiResult {
  /**  */
  result?: PolicyScheduleEventDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PolicyViewerListItemDto {
  /**  */
  id?: number;

  /**  */
  status?: PolicyStatus;

  /**  */
  name?: string;

  /**  */
  userName?: string;

  /**  */
  isLockedOut?: boolean;

  /**  */
  isScheduledForDeletion?: boolean;

  /**  */
  lockoutEnd?: string;

  /**  */
  scheduledDate?: string;

  /**  */
  children?: string[];

  /**  */
  viewerType?: string;

  /**  */
  familyName?: string;

  /**  */
  familyId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  policyName?: string;

  /**  */
  viewerPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;
}

export interface PolicyViewerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PolicyViewerListItemDto[];
}

export interface PolicyViewerListItemDtoDataTablePageApiResult {
  /**  */
  result?: PolicyViewerListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PublicCameraDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  thumbnailUrl?: string;

  /**  */
  staticImageUrl?: string;

  /**  */
  streamUrl?: string;

  /**  */
  isPasswordProtected?: boolean;
}

export interface PublicCameraDtoListApiResult {
  /**  */
  result?: PublicCameraDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface RegionDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  locationIds?: number[];

  /**  */
  userCount?: number;
}

export interface RegionDtoApiResult {
  /**  */
  result?: RegionDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface RegionListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  locations?: string[];
}

export interface RegionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: RegionListItemDto[];
}

export interface RegionListItemDtoDataTablePageApiResult {
  /**  */
  result?: RegionListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface RegisterIntegratedViewerCommand {
  /**  */
  inviteId?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  viewer?: ViewerDto;
}

export interface RegisterTokenCommand {
  /**  */
  token?: string;
}

export interface RegisterUserCommand {
  /**  */
  userName?: string;

  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface RelativeMaxCountConfigurationItemDto {
  /**  */
  value?: number;

  /**  */
  isEnabled?: boolean;
}

export interface RequestViewerClassChangeCommand {
  /**  */
  classIds?: number[];
}

export interface ResetOAuthSecretCommand {
  /**  */
  id?: string;
}

export interface ResetPasswordCommand {
  /**  */
  userName?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  code?: string;
}

export interface ResetPasswordResult {
  /**  */
  isSuccessful?: boolean;

  /**  */
  isViewer?: boolean;
}

export interface ResetPasswordResultApiResult {
  /**  */
  result?: ResetPasswordResult;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface RoomRequestListItemDto {
  /**  */
  id?: number;

  /**  */
  requestedDateUtc?: string;

  /**  */
  viewerName?: string;

  /**  */
  userName?: string;

  /**  */
  childName?: string;

  /**  */
  className?: string;

  /**  */
  viewerFamilyName?: string;

  /**  */
  familyId?: number;

  /**  */
  locationId?: number;

  /**  */
  childId?: number;

  /**  */
  classId?: number;
}

export interface RoomRequestListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: RoomRequestListItemDto[];
}

export interface RoomRequestListItemDtoDataTablePageApiResult {
  /**  */
  result?: RoomRequestListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ScheduleAutomatedApprovalCommand {
  /**  */
  locationId?: number;

  /**  */
  viewerId?: number;

  /**  */
  scheduledDate?: string;
}

export interface ScheduleClassesCommand {
  /**  */
  name?: string;

  /**  */
  toClassIds?: number[];

  /**  */
  fromClassIds?: number[];

  /**  */
  locationId?: number;

  /**  */
  childrenIds?: number[];

  /**  */
  scheduledDate?: string;
}

export interface ScheduleViewerDeleteCommand {
  /**  */
  viewerId?: number;

  /**  */
  scheduledDate?: string;
}

export interface ScheduledAutomationListItemDto {
  /**  */
  id?: number;

  /**  */
  type?: ScheduledAutomationType;

  /**  */
  scheduledDate?: string;

  /**  */
  scheduleInformativeMessage?: string;
}

export interface ScheduledAutomationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ScheduledAutomationListItemDto[];
}

export interface ScheduledAutomationListItemDtoDataTablePageApiResult {
  /**  */
  result?: ScheduledAutomationListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ScheduledClassListItemDto {
  /**  */
  id?: number;

  /**  */
  scheduledAutomationId?: number;

  /**  */
  name?: string;

  /**  */
  scheduledDate?: string;

  /**  */
  childName?: string;

  /**  */
  className?: string;

  /**  */
  class?: ClassCameraDto;

  /**  */
  classId?: number;

  /**  */
  scheduledAutomationType?: ScheduledAutomationType;

  /**  */
  scheduledClassMessage?: string;
}

export interface ScheduledClassListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ScheduledClassListItemDto[];
}

export interface ScheduledClassListItemDtoDataTablePageApiResult {
  /**  */
  result?: ScheduledClassListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ScheduledRegistrationListItemDto {
  /**  */
  scheduledApprovalDate?: string;

  /**  */
  children?: string[];

  /**  */
  name?: string;

  /**  */
  userName?: string;

  /**  */
  viewerType?: string;

  /**  */
  familyName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  policyName?: string;

  /**  */
  familyPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;

  /**  */
  locationId?: number;

  /**  */
  familyId?: number;

  /**  */
  id?: number;

  /**  */
  hasSuggestedFamilyMerge?: boolean;

  /**  */
  familyIsApproved?: string;

  /**  */
  type?: string;
}

export interface ScheduledRegistrationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ScheduledRegistrationListItemDto[];
}

export interface ScheduledRegistrationListItemDtoDataTablePageApiResult {
  /**  */
  result?: ScheduledRegistrationListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ScheduledViewerDeletionListItemDto {
  /**  */
  scheduledDate?: string;

  /**  */
  fullName?: string;

  /**  */
  userName?: string;

  /**  */
  isScheduledForDeletion?: boolean;

  /**  */
  isLockedOut?: boolean;

  /**  */
  lockoutEnd?: string;

  /**  */
  children?: string[];

  /**  */
  viewerType?: string;

  /**  */
  familyName?: string;

  /**  */
  familyId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  viewerPolicyName?: string;

  /**  */
  viewerPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;

  /**  */
  isPrimaryUser?: boolean;

  /**  */
  scheduledViewerDeletionId?: number;

  /**  */
  viewerId?: number;

  /**  */
  id?: number;
}

export interface ScheduledViewerDeletionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ScheduledViewerDeletionListItemDto[];
}

export interface ScheduledViewerDeletionListItemDtoDataTablePageApiResult {
  /**  */
  result?: ScheduledViewerDeletionListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SendConfirmAccountEmailCommand {
  /**  */
  userName?: string;
}

export interface SendVerificationSmsCommand {
  /**  */
  userName?: string;

  /**  */
  phoneNumber?: string;
}

export interface SessionValidOrInvalidCommand {
  /**  */
  viewerCameraSessionId?: number;

  /**  */
  isValid?: boolean;
}

export interface SetAdminLocationPolicyCommand {
  /**  */
  locationId?: number;

  /**  */
  guardianMaxCountRestriction?: AdminGuardianMaxCountConfigurationItemDto;

  /**  */
  relativeMaxCountRestriction?: AdminRelativeMaxCountConfigurationItemDto;

  /**  */
  sessionCountRestriction?: AdminSessionCountConfigurationItemDto;

  /**  */
  sessionLengthRestriction?: AdminSessionLengthConfigurationItemDto;

  /**  */
  desktopAccessRestriction?: AdminDesktopAccessConfigurationItemDto;

  /**  */
  mobileAccessRestriction?: AdminMobileAccessConfigurationItemDto;

  /**  */
  streamShieldRestriction?: AdminStreamShieldConfigurationItemDto;
}

export interface SetLocationKeyForIntegrationCommand {
  /**  */
  locationId?: number;

  /**  */
  integrationId?: number;

  /**  */
  locationKey?: string;
}

export interface Setting {
  /**  */
  id?: number;

  /**  */
  key?: string;

  /**  */
  value?: string;

  /**  */
  isEnabled?: boolean;
}

export interface SettingApiResult {
  /**  */
  result?: Setting;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SettingDto {
  /**  */
  id?: number;

  /**  */
  key?: string;

  /**  */
  value?: string;

  /**  */
  isEnabled?: boolean;
}

export interface SettingDtoApiResult {
  /**  */
  result?: SettingDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SettingDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SettingDto[];
}

export interface SettingDtoDataTablePageApiResult {
  /**  */
  result?: SettingDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StatusResult {
  /**  */
  name?: string;

  /**  */
  status?: string;
}

export interface StatusResultListApiResult {
  /**  */
  result?: StatusResult[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StreamShieldAccountReactivationCommand {
  /**  */
  viewerId?: number;
}

export interface StreamShieldEnabledConfigurationItemDto {
  /**  */
  value?: boolean;

  /**  */
  isEnabled?: boolean;
}

export interface StringApiResult {
  /**  */
  result?: string;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StringOptionItemDto {
  /**  */
  value?: string;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;

  /**  */
  isDisabled?: boolean;
}

export interface StringOptionItemDtoListApiResult {
  /**  */
  result?: StringOptionItemDto[];

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SubmitRoomRequestCommand {
  /**  */
  childId?: number;

  /**  */
  classId?: number;
}

export interface ToggleFeatureFlagDto {
  /**  */
  id?: number;

  /**  */
  enabled?: boolean;
}

export interface TransferClassCommand {
  /**  */
  toClassIds?: number[];

  /**  */
  fromClassIds?: number[];

  /**  */
  locationId?: number;

  /**  */
  childrenIds?: number[];
}

export interface TransferFamilyMemberCommand {
  /**  */
  familyId?: number;

  /**  */
  viewerId?: number;

  /**  */
  newPrimaryUserId?: number;

  /**  */
  shouldCreate?: boolean;

  /**  */
  createFamilyCommand?: CreateFamilyWithExistingViewerCommand;
}

export interface UnscheduleClassAdditionOrRemovalCommand {
  /**  */
  scheduledAutomationType?: ScheduledAutomationType;

  /**  */
  id?: number;
}

export interface UnscheduleViewerDeleteCommand {
  /**  */
  scheduledAutomationId?: number;
}

export interface UpdateApiKeyCommand {
  /**  */
  id?: number;

  /**  */
  isValid?: boolean;
}

export interface UpdateCameraCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  shortName?: string;

  /**  */
  streamUrl?: string;

  /**  */
  motionJpgStreamUrl?: string;

  /**  */
  enableHlsProxy?: boolean;

  /**  */
  enableHlsCaching?: boolean;

  /**  */
  overrideStreamUrl?: boolean;

  /**  */
  thumbnailUrl?: string;

  /**  */
  overrideThumbnailUrl?: boolean;

  /**  */
  classIds?: number[];

  /**  */
  thumbnailFile?: any;

  /**  */
  locationId?: number;

  /**  */
  nvrServerId?: number;

  /**  */
  aiOnly?: boolean;
}

export interface UpdateCameraPolicyConfigurationCommand {
  /**  */
  id?: number;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface UpdateCameraPolicyDetailsCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface UpdateChildCommand {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  classIds?: number[];
}

export interface UpdateClassCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  cameraIds?: number[];

  /**  */
  locationId?: number;

  /**  */
  areCamerasPublic?: boolean;

  /**  */
  isPasswordProtected?: boolean;

  /**  */
  newCameraPassword?: string;
}

export interface UpdateClassPolicyConfigurationCommand {
  /**  */
  id?: number;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface UpdateClassPolicyDetailsCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface UpdateEventCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  eventDate?: string;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;

  /**  */
  locationId?: number;

  /**  */
  classIds?: number[];

  /**  */
  childIds?: number[];

  /**  */
  cameraIds?: number[];

  /**  */
  thumbnailImage?: any;

  /**  */
  sendAnnouncement?: boolean;

  /**  */
  announcementNotificationDate?: string;

  /**  */
  announcementNotificationTime?: string;
}

export interface UpdateFamilyPolicyConfigurationCommand {
  /**  */
  id?: number;

  /**  */
  viewerCameraSessionMaxCountRestriction?: ViewerCameraSessionMaxCountConfigurationItemDto;

  /**  */
  viewerCameraSessionMaxLengthRestriction?: ViewerCameraSessionMaxLengthConfigurationItemDto;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface UpdateFamilyPolicyDetailsCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface UpdateIntegrationPolicyConfigurationCommand {
  /**  */
  id?: number;

  /**  */
  viewerCameraSessionMaxCountRestriction?: ViewerCameraSessionMaxCountConfigurationItemDto;

  /**  */
  viewerCameraSessionMaxLengthRestriction?: ViewerCameraSessionMaxLengthConfigurationItemDto;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  integrationDownShowAllCamerasEnabledRestriction?: IntegrationDownShowAllCamerasEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface UpdateLicensePlateCommand {
  /**  */
  plateNumber?: string;

  /**  */
  viewerId?: number;

  /**  */
  id?: number;

  /**  */
  locationId?: number;
}

export interface UpdateLocationAiPartnerConfigurationsCommand {
  /**  */
  locationId?: number;

  /**  */
  liveSitterConfiguration?: LiveSitterConfigurationDto;
}

export interface UpdateLocationCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  website?: string;

  /**  */
  timeZoneId?: string;

  /**  */
  uptimeUrl?: string;

  /**  */
  address?: AddressDto;

  /**  */
  organizationId?: number;

  /**  */
  userIds?: number[];

  /**  */
  legacyId?: number;

  /**  */
  enterpriseLocationNumber?: number;

  /**  */
  isLprEnabled?: boolean;

  /**  */
  isLprNotificationsEnabled?: boolean;

  /**  */
  isAdminPortalCameraViewingOnsiteOnly?: boolean;

  /**  */
  isPublicCameraSupportEnabled?: boolean;

  /**  */
  overrideProgramType?: ProgramType;

  /**  */
  isEnterprise?: boolean;
}

export interface UpdateLocationNotificationSettingsCommand {
  /**  */
  id?: number;

  /**  */
  isLprNotificationsEnabled?: boolean;
}

export interface UpdateLocationPolicyConfigurationCommand {
  /**  */
  id?: number;

  /**  */
  guardianMaxCountRestriction?: GuardianMaxCountConfigurationItemDto;

  /**  */
  relativeMaxCountRestriction?: RelativeMaxCountConfigurationItemDto;

  /**  */
  viewerCameraSessionMaxCountRestriction?: ViewerCameraSessionMaxCountConfigurationItemDto;

  /**  */
  viewerCameraSessionMaxLengthRestriction?: ViewerCameraSessionMaxLengthConfigurationItemDto;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface UpdateLocationPolicyDetailsCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface UpdateNvrServerCommand {
  /**  */
  locationId?: number;

  /**  */
  id?: number;

  /**  */
  cameraIds?: number[];

  /**  */
  name?: string;

  /**  */
  domain?: string;

  /**  */
  nvrWanIpAddress?: string;

  /**  */
  nvrLanIpAddress?: string;

  /**  */
  serverUsername?: string;

  /**  */
  serverPassword?: string;

  /**  */
  cameraUsername?: string;

  /**  */
  cameraPassword?: string;
}

export interface UpdateOrganizationCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  address?: AddressDto;

  /**  */
  pocName?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  position?: string;

  /**  */
  programType?: string;
}

export interface UpdateRegionCommand {
  /**  */
  id?: number;

  /**  */
  organizationId?: number;

  /**  */
  name?: string;

  /**  */
  locationIds?: number[];
}

export interface UpdateScheduleAutomationCommand {
  /**  */
  name?: string;

  /**  */
  scheduledAutomationId?: number;

  /**  */
  scheduledDate?: string;

  /**  */
  scheduledAutomationType?: ScheduledAutomationType;
}

export interface UpdateScheduleViewerDeleteCommand {
  /**  */
  scheduledViewerDeletionId?: number;

  /**  */
  scheduledDate?: string;
}

export interface UpdateUserCommand {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRole?: number;

  /**  */
  locationIds?: number[];

  /**  */
  emailSubscriptions?: string[];

  /**  */
  smsSubscriptions?: string[];

  /**  */
  regionId?: number;

  /**  */
  organizationId?: number;

  /**  */
  profilePhoto?: any;

  /**  */
  hasPortalCameraAccess?: boolean;

  /**  */
  hasMobileCameraAccess?: boolean;

  /**  */
  hasAiDashboardAccess?: boolean;
}

export interface UpdateUserNotificationPreferencesCommand {
  /**  */
  emailSubscriptions?: string[];

  /**  */
  smsSubscriptions?: string[];
}

export interface UpdateViewerCommand {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  viewerType?: string;

  /**  */
  address?: AddressDto;

  /**  */
  plateNumber?: string;

  /**  */
  overrideLicensePlateRequirement?: boolean;
}

export interface UpdateViewerLicensePlateCommand {
  /**  */
  id?: number;

  /**  */
  plateNumber?: string;

  /**  */
  overrideLicensePlateRequirement?: boolean;
}

export interface UpdateViewerPolicyConfigurationCommand {
  /**  */
  id?: number;

  /**  */
  viewerCameraSessionMaxCountRestriction?: ViewerCameraSessionMaxCountConfigurationItemDto;

  /**  */
  viewerCameraSessionMaxLengthRestriction?: ViewerCameraSessionMaxLengthConfigurationItemDto;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface UpdateViewerPolicyDetailsCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface UserDto {
  /**  */
  id?: number;

  /**  */
  userName?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  userRole?: number;

  /**  */
  locationIds?: number[];

  /**  */
  regionId?: number;

  /**  */
  contactInformation?: ContactInformationDto[];

  /**  */
  profilePhotoGuid?: string;

  /**  */
  role?: string;

  /**  */
  claims?: string[];

  /**  */
  organizationId?: number;

  /**  */
  emailSubscriptions?: string[];

  /**  */
  smsSubscriptions?: string[];

  /**  */
  hasPortalCameraAccess?: boolean;

  /**  */
  hasMobileCameraAccess?: boolean;

  /**  */
  hasAiDashboardAccess?: boolean;
}

export interface UserDtoApiResult {
  /**  */
  result?: UserDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface UserListItemDto {
  /**  */
  userName?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  role?: string;

  /**  */
  roleDisplayName?: string;

  /**  */
  organizationName?: string;

  /**  */
  locations?: string[];

  /**  */
  region?: string;

  /**  */
  id?: number;

  /**  */
  isLockedOut?: boolean;

  /**  */
  isReadOnly?: boolean;
}

export interface UserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: UserListItemDto[];
}

export interface UserListItemDtoDataTablePageApiResult {
  /**  */
  result?: UserListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface UserLocationsWithNvrServerCountDataTableRequest {
  /**  */
  territoryIds?: number[];

  /**  */
  regionIds?: number[];

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface UserLocationsWithNvrServerListItemDto {
  /**  */
  id?: number;

  /**  */
  enterpriseLocationNumber?: number;

  /**  */
  name?: string;

  /**  */
  addressCity?: string;

  /**  */
  addressTerritoryName?: string;

  /**  */
  regionNames?: string[];

  /**  */
  nvrServers?: NvrServerSelectionDto[];

  /**  */
  uptimeUrl?: string;
}

export interface UserLocationsWithNvrServerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: UserLocationsWithNvrServerListItemDto[];
}

export interface UserLocationsWithNvrServerListItemDtoDataTablePageApiResult {
  /**  */
  result?: UserLocationsWithNvrServerListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface UserNotificationDto {
  /**  */
  key?: string;

  /**  */
  description?: string;
}

export interface UserNotificationsDto {
  /**  */
  email?: UserNotificationDto[];

  /**  */
  sms?: UserNotificationDto[];
}

export interface UserNotificationsDtoApiResult {
  /**  */
  result?: UserNotificationsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ValidateAccountStepQuery {
  /**  */
  userName?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  locationId?: number;
}

export interface ValidateChildrenStepQuery {
  /**  */
  children?: ChildDto[];

  /**  */
  locationId?: number;
}

export interface ValidateMyInfoStepQuery {
  /**  */
  locationId?: number;

  /**  */
  inviteId?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  address?: AddressDto;
}

export interface ValidationError {
  /**  */
  propertyName?: string;

  /**  */
  errorMessage?: string;
}

export interface VerifySmsCommand {
  /**  */
  userName?: string;

  /**  */
  token?: string;

  /**  */
  phoneNumber?: string;
}

export interface ViewerCameraSessionByViewerListItemDto {
  /**  */
  id?: number;

  /**  */
  createdDate?: string;

  /**  */
  hasActiveEvent?: boolean;

  /**  */
  isSessionValid?: boolean;

  /**  */
  isTodayInLocationTimeZone?: boolean;
}

export interface ViewerCameraSessionByViewerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ViewerCameraSessionByViewerListItemDto[];
}

export interface ViewerCameraSessionByViewerListItemDtoDataTablePageApiResult {
  /**  */
  result?: ViewerCameraSessionByViewerListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ViewerCameraSessionMaxCountConfigurationItemDto {
  /**  */
  value?: number;

  /**  */
  isEnabled?: boolean;
}

export interface ViewerCameraSessionMaxLengthConfigurationItemDto {
  /**  */
  valueInMinutes?: number;

  /**  */
  isEnabled?: boolean;
}

export interface ViewerCameraSessionsUsedDto {
  /**  */
  sessionMaxCountRestriction?: number;

  /**  */
  sessionsUsed?: number;

  /**  */
  policySourceName?: string;

  /**  */
  policySourceId?: number;

  /**  */
  policyType?: PolicyType;
}

export interface ViewerCameraSessionsUsedDtoApiResult {
  /**  */
  result?: ViewerCameraSessionsUsedDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ViewerDeletionDetailsDto {
  /**  */
  id?: number;

  /**  */
  isPrimaryUser?: boolean;

  /**  */
  isLastViewer?: boolean;

  /**  */
  parentGuardianOptions?: OptionItemDto[];

  /**  */
  familyName?: string;
}

export interface ViewerDeletionDetailsDtoApiResult {
  /**  */
  result?: ViewerDeletionDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ViewerDetailsDto {
  /**  */
  id?: number;

  /**  */
  fullName?: string;

  /**  */
  username?: string;

  /**  */
  emailAddress?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  locationId?: number;

  /**  */
  locationName?: string;

  /**  */
  isPrimaryUser?: boolean;

  /**  */
  shouldReview?: boolean;

  /**  */
  shouldRequestLicense?: boolean;

  /**  */
  viewerType?: string;

  /**  */
  familyId?: number;

  /**  */
  familyName?: string;

  /**  */
  viewerCameraSessionMaxCount?: number;

  /**  */
  viewerCameraSessionMaxLengthInMinutes?: number;

  /**  */
  sessionCount?: number;

  /**  */
  plateNumber?: string;

  /**  */
  nextEvent?: string;
}

export interface ViewerDetailsDtoApiResult {
  /**  */
  result?: ViewerDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ViewerDto {
  /**  */
  id?: number;

  /**  */
  userName?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  plateNumber?: string;

  /**  */
  overrideLicensePlateRequirement?: boolean;

  /**  */
  isPrimaryUser?: boolean;

  /**  */
  viewerType?: string;

  /**  */
  address?: AddressDto;

  /**  */
  familyId?: number;

  /**  */
  familyLocationId?: number;
}

export interface ViewerDtoApiResult {
  /**  */
  result?: ViewerDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ViewerForMerge {
  /**  */
  id?: number;

  /**  */
  fullName?: string;

  /**  */
  isPrimaryUser?: boolean;

  /**  */
  viewerType?: string;
}

export interface ViewerListItemDto {
  /**  */
  id?: number;

  /**  */
  isLockedOut?: boolean;

  /**  */
  isScheduledForDeletion?: boolean;

  /**  */
  userName?: string;

  /**  */
  name?: string;

  /**  */
  viewerType?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  classes?: ClassCameraDto[];

  /**  */
  lockoutEnd?: string;

  /**  */
  scheduledDate?: string;

  /**  */
  policyName?: string;

  /**  */
  viewerPolicyId?: number;

  /**  */
  isPolicyEnforced?: boolean;
}

export interface ViewerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ViewerListItemDto[];
}

export interface ViewerListItemDtoDataTablePageApiResult {
  /**  */
  result?: ViewerListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ViewerPoliciesDataTableRequest {
  /**  */
  locationId?: number;

  /**  */
  viewerIds?: number[];

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface ViewerPolicyConfigurationDto {
  /**  */
  id?: number;

  /**  */
  viewerCameraSessionMaxCountRestriction?: ViewerCameraSessionMaxCountConfigurationItemDto;

  /**  */
  viewerCameraSessionMaxLengthRestriction?: ViewerCameraSessionMaxLengthConfigurationItemDto;

  /**  */
  desktopAccessEnabledRestriction?: DesktopAccessEnabledConfigurationItemDto;

  /**  */
  mobileAccessEnabledRestriction?: MobileAccessEnabledConfigurationItemDto;

  /**  */
  streamShieldEnabledRestriction?: StreamShieldEnabledConfigurationItemDto;

  /**  */
  adminLocationPolicy?: AdminLocationPolicyDto;
}

export interface ViewerPolicyConfigurationDtoApiResult {
  /**  */
  result?: ViewerPolicyConfigurationDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ViewerPolicyDetailsDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface ViewerPolicyDetailsDtoApiResult {
  /**  */
  result?: ViewerPolicyDetailsDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ViewerPolicyListItemConfigurationDto {
  /**  */
  scheduleRestrictionIsEnabled?: boolean;

  /**  */
  viewerCameraSessionMaxCountRestrictionIsEnabled?: boolean;

  /**  */
  viewerCameraSessionMaxLengthRestrictionIsEnabled?: boolean;

  /**  */
  desktopAccessEnabledRestrictionIsEnabled?: boolean;

  /**  */
  mobileAccessEnabledRestrictionIsEnabled?: boolean;

  /**  */
  streamShieldEnabledRestrictionIsEnabled?: boolean;
}

export interface ViewerPolicyListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  configuration?: ViewerPolicyListItemConfigurationDto;

  /**  */
  assignedActiveViewerCount?: number;

  /**  */
  assignedInactiveViewerCount?: number;

  /**  */
  unassignedInactiveViewerCount?: number;
}

export interface ViewerPolicyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ViewerPolicyListItemDto[];
}

export interface ViewerPolicyListItemDtoDataTablePageApiResult {
  /**  */
  result?: ViewerPolicyListItemDtoDataTablePage;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ViewerPolicySummaryDto {
  /**  */
  isEnabled?: boolean;

  /**  */
  assignedActiveViewerCount?: number;

  /**  */
  assignedInactiveViewerCount?: number;

  /**  */
  assignedViewerCount?: number;

  /**  */
  unassignedViewerCount?: number;

  /**  */
  id?: number;

  /**  */
  locationId?: number;

  /**  */
  name?: string;
}

export interface ViewerPolicySummaryDtoApiResult {
  /**  */
  result?: ViewerPolicySummaryDto;

  /**  */
  locationId?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ViewerPolicyViewerPolicyScheduleViewerPolicyScheduleEventCreatePolicyScheduleEventCommand {
  /**  */
  policyId?: number;

  /**  */
  daysOfWeek?: string[];

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface ViewerPolicyViewerPolicyScheduleViewerPolicyScheduleEventEnableOrDisablePolicyCommand {
  /**  */
  id?: number;

  /**  */
  isEnabled?: boolean;
}

export interface ViewerPolicyViewerPolicyScheduleViewerPolicyScheduleEventEnableOrDisablePolicyScheduleCommand {
  /**  */
  policyId?: number;

  /**  */
  isEnabled?: boolean;
}

export interface ViewerPolicyViewerPolicyScheduleViewerPolicyScheduleEventUpdatePolicyScheduleEventCommand {
  /**  */
  id?: number;

  /**  */
  startTime?: string;

  /**  */
  endTime?: string;
}

export interface ViewersByPolicyDataTableRequest {
  /**  */
  viewerPolicyId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export enum AdminPolicyEnforcementType {
  'Off' = 'Off',
  'Location' = 'Location',
  'All' = 'All'
}

export enum DayOfWeek {
  'Sunday' = 'Sunday',
  'Monday' = 'Monday',
  'Tuesday' = 'Tuesday',
  'Wednesday' = 'Wednesday',
  'Thursday' = 'Thursday',
  'Friday' = 'Friday',
  'Saturday' = 'Saturday'
}

export enum EnrollmentStatus {
  'Available' = 'Available',
  'Unavailable' = 'Unavailable',
  'EnrollmentRequested' = 'EnrollmentRequested',
  'EnrollmentGranted' = 'EnrollmentGranted',
  'ClassesEnrolled' = 'ClassesEnrolled',
  'CamerasAssigned' = 'CamerasAssigned',
  'Complete' = 'Complete'
}

export enum FeatureFlagType {
  'DebugMode' = 'DebugMode',
  'WebhookLogging' = 'WebhookLogging',
  'ScoutXMessageProcessing' = 'ScoutXMessageProcessing',
  'FamilyMemberTransfer' = 'FamilyMemberTransfer',
  'CurrentEvents' = 'CurrentEvents',
  'ViewPastEvents' = 'ViewPastEvents',
  'Sms' = 'Sms',
  'AdminLocationPolicy' = 'AdminLocationPolicy',
  'CanViewerSeeEventCameras' = 'CanViewerSeeEventCameras',
  'HlsProxyStreams' = 'HlsProxyStreams',
  'InvalidateSessionsFromTodayForFamily' = 'InvalidateSessionsFromTodayForFamily',
  'OrganizationTabs' = 'OrganizationTabs',
  'IframeBlueIris' = 'IframeBlueIris',
  'HlsRealTimeChecks' = 'HlsRealTimeChecks',
  'LicensePlateRecognition' = 'LicensePlateRecognition',
  'LprCurrentLocation' = 'LprCurrentLocation',
  'HlsCaching' = 'HlsCaching',
  'MultipleNvrServerSupport' = 'MultipleNvrServerSupport',
  'FamilyProgram' = 'FamilyProgram',
  'RequiresLicensePlate' = 'RequiresLicensePlate',
  'LiveDetectionCountWidget' = 'LiveDetectionCountWidget',
  'FeatureHighlightCarousel' = 'FeatureHighlightCarousel',
  'PreferencesPage' = 'PreferencesPage',
  'AutomaticLoginToBlueIris' = 'AutomaticLoginToBlueIris',
  'SendAdditionalTelemetry' = 'SendAdditionalTelemetry',
  'PerUserCameraPermissions' = 'PerUserCameraPermissions',
  'LocationUptimeUrl' = 'LocationUptimeUrl',
  'UserNotificationSubscriptions' = 'UserNotificationSubscriptions',
  'NvrIntegrationApi' = 'NvrIntegrationApi',
  'OrgManagerManageRegions' = 'OrgManagerManageRegions',
  'AiPartnerConfiguration' = 'AiPartnerConfiguration',
  'MobileNvrAccess' = 'MobileNvrAccess',
  'AiOnly' = 'AiOnly',
  'LiveSitterLocation' = 'LiveSitterLocation',
  'ProxyLoadingStream' = 'ProxyLoadingStream',
  'CacheLoadingStream' = 'CacheLoadingStream',
  'AiDashboardLink' = 'AiDashboardLink',
  'IntegrationForceSync' = 'IntegrationForceSync',
  'KangarootimeSyncSpaces' = 'KangarootimeSyncSpaces',
  'LiveSitterSendsExternalLocationId' = 'LiveSitterSendsExternalLocationId',
  'OnsiteOnlyViewingOption' = 'OnsiteOnlyViewingOption',
  'KangarootimeIgnoreLock' = 'KangarootimeIgnoreLock',
  'RoomRequest' = 'RoomRequest',
  'LocationNotConfiguredForAiDashboardPage' = 'LocationNotConfiguredForAiDashboardPage',
  'ReviewRegistrations' = 'ReviewRegistrations',
  'PolicyConfiguration' = 'PolicyConfiguration',
  'ViewerClient' = 'ViewerClient',
  'InvalidateViewerCameraSessionsFromTodayByLocation' = 'InvalidateViewerCameraSessionsFromTodayByLocation',
  'PublicCameraSupportGlobal' = 'PublicCameraSupportGlobal',
  'PublicCameraSupportCurrentLocation' = 'PublicCameraSupportCurrentLocation'
}

export enum MergeAction {
  'Merge' = 'Merge',
  'MergeAndApprove' = 'MergeAndApprove',
  'MergeAndScheduleApproval' = 'MergeAndScheduleApproval'
}

export enum PolicyStatus {
  'Active' = 'Active',
  'Paused' = 'Paused',
  'Inactive' = 'Inactive'
}

export enum PolicyType {
  'Viewer' = 'Viewer',
  'Family' = 'Family',
  'Camera' = 'Camera',
  'Class' = 'Class',
  'Location' = 'Location'
}

export enum ProgramType {
  'FamilyProgram' = 'FamilyProgram',
  'AdminOnlyProgram' = 'AdminOnlyProgram'
}

export enum ResourceProviderType {
  'Unknown' = 'Unknown',
  'FileSystem' = 'FileSystem',
  'AzureBlob' = 'AzureBlob',
  'CloudflareR2' = 'CloudflareR2'
}

export enum RoleContextType {
  'Location' = 'Location',
  'Organization' = 'Organization',
  'Global' = 'Global'
}

export enum ScheduledAutomationType {
  'ApproveRegistration' = 'ApproveRegistration',
  'AddChildClass' = 'AddChildClass',
  'RemoveChildClass' = 'RemoveChildClass',
  'DeleteViewer' = 'DeleteViewer',
  'TransferChildFromClassToClass' = 'TransferChildFromClassToClass'
}
