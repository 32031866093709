import { ReactNode, useRef } from 'react';
import classNames from 'classnames';
import ScrollArea from './ScrollArea';
import { ShadowScrollContextProvider } from './ShadowScrollContext';

export interface ShadowScrollProps {
  children?: ReactNode;
  className?: string;
}

/** provides the actual area to scroll - because there are two elements representing the container + scroll area, make sure you set the proper overflow + classes to ensure scrolling occurs properly within this element */
ShadowScroll.ScrollArea = ScrollArea;

/** ShadowScroll (when combined with ShadowScroll.ScrollArea) applies inset shadows to designate content as being below the fold */
export default function ShadowScroll({
  children,
  className,
}: ShadowScrollProps) {
  const shadowRef = useRef<HTMLDivElement>(null);

  return (
    <ShadowScrollContextProvider value={shadowRef}>
      <div className={classNames(className, 'relative')}>
        <div
          className="absolute inset-0 z-10 pointer-events-none"
          ref={shadowRef}
        />
        {children}
      </div>
    </ShadowScrollContextProvider>
  );
}
