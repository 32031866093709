import { useEffect } from 'react';
import { debounce, throttle } from 'lodash';

/** Some browsers don't resize the body when the keyboard covers it, this gives a consistent variable for css to listen to for setting the value */
export default function JsResizeHelper() {
  useEffect(() => {
    const fastCycle = throttle(setHeight, 200, {
      leading: false,
      trailing: true,
    });
    const middleDebounce = debounce(setHeight, 500);
    const finalCheck = debounce(setHeight, 2000);

    const fancyCycle = () => {
      fastCycle();
      middleDebounce();
      finalCheck();
    };

    window.addEventListener('gestureend', fancyCycle, { passive: true });
    window.addEventListener('resize', fancyCycle, { passive: true });
    window.addEventListener('touchend', fancyCycle, { passive: true });
    window.addEventListener('focusout', fancyCycle, { passive: true });
    setHeight();

    return () => {
      fastCycle.cancel();
      middleDebounce.cancel();
      finalCheck.cancel();
      window.removeEventListener('gestureend', fancyCycle);
      window.removeEventListener('resize', fancyCycle);
      window.removeEventListener('touchend', fancyCycle);
      window.removeEventListener('focusout', fancyCycle);
    };

    function setHeight() {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
    }
  }, []);

  return null;
}
