import { FormatDateOptions, useIntl } from 'react-intl';
import Cell from './Cell';

const fullExpanded: FormatDateOptions = {
  month: 'short',
  day: '2-digit',
  weekday: 'long',
  hour: 'numeric',
  year: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
};

// default (dateDisplayFormat prop not passed): 12/24/2022
// short: 12/24/22
// full: Saturday, December 24, 2022
// medium: Dec 24, 2022
// long: December 24, 2022
type dateDisplayFormats = 'short' | 'full' | 'medium' | 'long';

// default (timeDisplayFormat prop not passed): 02:31 PM
// short: 2:31 PM
type timeDisplayFormats = 'short';

// default (timeZoneNameDisplayFormat prop not passed): CDT
// long: Central Daylight Time
type timeZoneNameDisplayFormats = 'long';

interface FormattedDateTimeDisplayProps {
  dateTime: string;
  dateDisplayFormat?: dateDisplayFormats;
  timeDisplayFormat?: timeDisplayFormats;
  timeZoneNameDisplayFormat?: timeZoneNameDisplayFormats;
}

export function FormattedDateTimeDisplay({
  dateTime,
  dateDisplayFormat,
  timeDisplayFormat,
  timeZoneNameDisplayFormat,
}: FormattedDateTimeDisplayProps) {
  const intl = useIntl();
  const dateAndTime = dateTimeOnly(dateTime);
  const timePart = timePartCreator(
    timeDisplayFormat,
    timeZoneNameDisplayFormat
  );

  const dateTimeComponent = (
    <>
      <span className="date-part">
        {intl.formatDate(dateAndTime, { dateStyle: dateDisplayFormat })}
      </span>
      <span className="time-part">
        {intl.formatDate(dateAndTime, timePart)}
      </span>
    </>
  );

  return (
    <Cell
      value={dateTimeComponent}
      title={intl.formatDate(dateTime, fullExpanded)}
    />
  );

  function dateTimeOnly(dateTime: string) {
    const dateTimeRegex = /^.+[tT].*[Zz+-].*$/;
    const isValidDateTime = dateTimeRegex.test(dateTime);
    const dateTimeValue = isValidDateTime ? Date.parse(dateTime) : NaN;
    const isNotValidDateTime = isNaN(dateTimeValue);

    if (isNotValidDateTime) {
      const errorMessage = `Invalid "date time" value of ${dateTime} provided to FormattedDateTimeDisplay component.Please provide ISO 8601 string that includes the time zone designator. Sample strings: 2022-09-24T:08:54:12+00:00, 2022-09-24T:08:54:12Z, 2022-09-24T:08:54:12.123-05:00`;
      if (process.env.NODE_ENV !== 'production') {
        throw new Error(errorMessage);
      } else {
        console.error(errorMessage);
      }
    } else {
      return dateTimeValue;
    }
  }

  function timePartCreator(
    timeFormat: timeDisplayFormats | undefined,
    timeZoneNameFormat: timeZoneNameDisplayFormats | undefined
  ): FormatDateOptions {
    if (timeFormat === 'short') {
      return {
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: timeZoneNameFormat || 'short',
      };
    } else {
      return {
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: timeZoneNameFormat || 'short',
      };
    }
  }
}
